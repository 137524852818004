// NavbarTop.js
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { ReactComponent as NavbarLogo } from '../assets/NavbarLogo.svg'
import AppConfig from '../config/AppConfig';
import { Link } from "react-router-dom";

const NavbarTop = () => {

    const [scrollpos, setScrollpos] = useState(0);
    const [scrolled, setScrolled] = useState(false);

    function handleScroll(header_height) {
        const currentScrollPos = window.scrollY;
        setScrollpos(currentScrollPos);
        if (currentScrollPos > header_height) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    }

    useEffect(() => {
        const header_height = document.querySelector(".navbar").offsetHeight;
        window.addEventListener('scroll', () => handleScroll(header_height));
        return () => {
            window.removeEventListener('scroll', () => handleScroll(header_height));
        };
    }, []);



    return (
        <Navbar id="navScroll" expand="lg" className={scrolled ? "fixed-top scrolled shadow-sm" : 'fixed-top'} tabIndex="0">
            <Container>
                <Navbar.Brand as={Link} to="/" className="pe-4 fs-4">
                    <NavbarLogo width="32" height="32" fill="#16d779"/>
                    {' '}
                    <span className="ms-1 fw-bolder navbar-brand-name">{AppConfig.name}</span>
                </Navbar.Brand>
                <Navbar.Toggle className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </Navbar.Toggle>

                <Navbar.Collapse id="navbarSupportedContent">
                    <Nav className="navbar-nav me-auto mb-2 mb-lg-0">
                        <Nav.Link as={Link} to="/news" aria-label="Brings you to the latest AI News">Latest News</Nav.Link>
                        <Nav.Link as={Link} to="/models" aria-label="Brings you to the best GenAI Model Leaderboard">GenAI Model Leaderboard</Nav.Link>
                        <Nav.Link as={Link} to="/repos" aria-label="Brings you to the hottest AI Repositories">Trending Repos</Nav.Link>
                        <Nav.Link as={Link} to="/prompts" aria-label="Brings you to the most creative GenAI Prompt Ideas">Prompt Ideas</Nav.Link>
                        <Nav.Link as={Link} to="/guide" aria-label="Brings you to the AI Guide">AI Guide</Nav.Link>
                        {/* <Nav.Link as={Link} to="/faq" aria-label="Brings you to the AI FAQ">AI FAQ</Nav.Link> */}
                    </Nav>
                </Navbar.Collapse>

            </Container>

        </Navbar>
    );
};

export default NavbarTop;