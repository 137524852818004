// NewsDetail.js

import React from 'react';
import { Modal } from 'react-bootstrap';
import formatDate from '../utils/formatDate';
import parseHtml from '../utils/parseHtml';
import { Link } from "react-router-dom";

const NewsDetail = ({ show, onHide, newsItem, onPrev, onNext }) => {
    return (
        <Modal 
            show={show} 
            onHide={onHide} 
            dialogClassName="news-modal"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="row modal-news-container">
                    {/* <div className="col modal-nav-button modal-nav-button-start" onClick={onPrev}>
                        <p>{'<'}</p>
                    </div> */}
                    <div className="modal-news mb-4">
                        {/* <img src={newsItem.image} alt={newsItem.title} className="img-fluid" /> */}
                        <div className="bg-image hover-overlay shadow-1-strong ripple rounded-5 mb-4">
                            <img src={newsItem.image} className="img-fluid img-ratio-16-9" alt={newsItem.title} />
                        </div>

                        <h4><strong>{newsItem.title}</strong></h4>
                        <div className="row mb-3">
                            <i>{formatDate(newsItem.published)}</i>
                        </div>
                        <p className="modal-news-text">
                            {parseHtml(newsItem.summary)}
                        </p>
                        <Link to={newsItem.link} replace={true}>Read More</Link>
                        {/* <button type="button" className="btn btn-primary">Read more</button> */}
                    </div>
                    {/* <div className="modal-nav-button modal-nav-button-end" onClick={onNext}>
                        <p>{'>'}</p>
                    </div> */}
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button variant="primary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer> */}
        </Modal>
    );
};

export default NewsDetail;
