// NewsProvider.js
import React, { useState, useEffect } from 'react';
import NewsContext from '../contexts/NewsContext';
import { db } from '../config/Firebase';
import { ref, get, query, orderByChild, startAt } from 'firebase/database';

const NewsProvider = ({ children }) => {
    const [news, setNews] = useState([]);
    const [loadingNews, setLoading] = useState(true);

    useEffect(() => {
        fetchNews();
    }, []);

    async function fetchNews() {
        // NOTE: this is the new firebase v9 web modular api style of a db query
        try {
        
            // Calculate the date two days ago
            var currentDate = new Date();
            var cutOffDate = new Date(currentDate);
            cutOffDate.setDate(currentDate.getDate() - 2);
            // console.log("cutOffDate:", cutOffDate)
            // Convert date to a Firebase-compatible format (e.g., Unix timestamp)
            var cutOffTimestamp = cutOffDate.getTime(); // in ms
            // console.log('[NewsProvider] cutoff timestamp:', cutOffTimestamp)

            const newsRef = ref(db, 'webcontent/news');
            const q = query(newsRef, orderByChild('published'), startAt(cutOffTimestamp));
            const snapshot = await get(q);
    
            if (snapshot.exists()) {
                const newsData = snapshot.val();
                if (newsData) {
                    const newsListComplete = Object.values(newsData);
                    setNews(sortNews(newsListComplete));
                    // console.log('[NewsProvider] Loaded news.')

                }
            } else {
                // console.log("No data available");
            }
        } catch (error) {
            // console.error(error);
        } finally {
            setLoading(false); // Set loading to false after fetching data
        }
        
    }

    function sortNews(news) {
        // go through the list of news and sort by date, newest first
        // console.log("unsorted:", news)
        return news.sort((a, b) =>{
            return new Date(b.published) - new Date(a.published)
        });
    }
    
    return (
        <NewsContext.Provider
        value={{ loadingNews, news }}
        >
            {children}
        </NewsContext.Provider>
    );
};

export default NewsProvider;
