// FaqPage.js
import React, { useEffect, useState } from 'react';
import NavbarTop from './NavbarTop';
import Spinner from 'react-bootstrap/Spinner';
import FooterBottom from './FooterBottom';
import Accordion from 'react-bootstrap/Accordion';

export default function FaqPage() {

    function FaqComponent() {
        return (
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <span>
                            What is <strong>Artificial Intelligence (AI)?</strong>
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        Artificial Intelligence (AI) refers to the intelligence exhibited by machines or software, distinct from human or animal intelligence. It's a branch of computer science dedicated to developing and exploring intelligent machines, commonly known as AIs.
                        <br /><br />
                        Widely employed across industry, government, and science, AI technology powers advanced web search engines (like Google Search), recommendation systems (used by YouTube, Amazon, and Netflix), speech recognition (e.g., Google Assistant, Siri, Alexa), self-driving cars (Waymo), generative tools (ChatGPT, AI art), and strategic game analysis (chess, Go).
                        <br /><br />
                        Alan Turing pioneered early research in Machine Intelligence, leading to the formal establishment of artificial intelligence as an academic discipline in 1956. Over time, AI experienced cycles of optimism and setbacks until 2012 when deep learning surpassed previous techniques, followed by a surge in interest with the transformer architecture in 2017, marking the AI spring of the early 2020s, with significant advancements primarily originating in the United States.
                        <br /><br />
                        AI research is categorized into sub-fields focused on specific goals and tools. Traditional objectives include reasoning, knowledge representation, planning, learning, natural language processing, perception, and support for robotics. The ultimate aim is achieving general intelligence—performing any task a human can.
                        <br /><br />
                        AI researchers address these challenges using diverse problem-solving techniques, such as search and optimization, formal logic, artificial neural networks, statistics, operations research, and economics. Additionally, AI draws insights from psychology, linguistics, philosophy, neuroscience, and other interdisciplinary fields.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <span>
                            What are <strong>GPTs?</strong>
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        

                        Generative Pre-trained Transformers (GPT) are advanced language models grounded in the connections between words in sentences, known as natural language processing. These text-based models undergo initial training on a vast corpus of text from the internet, predicting the next token (word, subword, or punctuation). Through this process, GPT accumulates knowledge about the world and can produce human-like text by predicting subsequent tokens. Additional training phases enhance the model's accuracy and usefulness, often employing reinforcement learning from human feedback (RLHF). While GPT models may still produce inaccuracies known as "hallucinations," efforts like RLHF and quality data can mitigate them. These models find applications in chatbots, allowing users to pose questions or make simple requests in plain text.

                        <br /> <br />

                        Current GPT Models and Services

                        Notable GPT models and services include Bard, ChatGPT, Grok, Claude, Copilot, and LLaMA. These models go beyond text processing, with multimodal GPT models capable of handling diverse data types such as images, videos, sound, and text.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <span>
                            What are the <strong>goals</strong> of AI?
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <strong>Reasoning and Problem-Solving</strong>
                        <br /><br />
                        Early AI focused on step-by-step reasoning, evolving to handle uncertainty with concepts from probability. However, large reasoning problems face challenges like "combinatorial explosion." Efficient and accurate reasoning remains an unsolved challenge.
                        <br /><br />
                        <strong>Knowledge Representation</strong>
                        <br /><br />
                        Representing knowledge through ontologies helps AI answer questions intelligently and make deductions. Knowledge bases and ontologies cover objects, relations, concepts, properties, and more. Acquiring knowledge for AI applications remains a significant challenge.
                        <br /><br />
                        <strong>Planning and Decision Making</strong>
                        <br /><br />
                        Agents, rational entities with goals, engage in automated planning and decision-making. In real-world scenarios, uncertainty is common, requiring agents to make probabilistic guesses. Game theory plays a role in decision-making involving multiple interacting agents.
                        <br /><br />
                        <strong>Learning</strong>
                        <br /><br />
                        Machine learning, integral to AI, includes unsupervised, supervised, reinforcement learning, and transfer learning. Deep learning, using artificial neural networks, has revolutionized AI, contributing to advancements in various learning types.
                        <br /><br />
                        <strong>Natural Language Processing (NLP)</strong>
                        <br /><br />
                        NLP enables programs to understand and communicate in human languages. Modern techniques like word embedding and transformers, backed by deep learning, have elevated NLP capabilities, achieving human-level scores on diverse real-world applications.
                        <br /><br />
                        <strong>Perception</strong>
                        <br /><br />
                        Machine perception involves analyzing input from sensors for understanding the world. Computer vision, part of machine perception, covers speech and image recognition, facial recognition, and robotic perception.
                        <br /><br />
                        <strong>Social Intelligence</strong>
                        <br /><br />
                        Affective computing, an interdisciplinary field, focuses on systems recognizing and simulating human emotions. Despite successes like sentiment analysis, challenges remain, and anthropomorphizing AI may create unrealistic expectations.
                        <br /><br />
                        <strong>General Intelligence</strong>
                        <br /><br />
                        The ultimate AI goal is artificial general intelligence, enabling machines to solve diverse problems with human-like breadth and versatility.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        <span>
                            What is AI <strong>used for?</strong>
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        AI and machine learning play a pivotal role in numerous aspects of our daily lives. From powering search engines like Google to enhancing online advertising, recommendation systems on platforms like Netflix, YouTube, and Amazon, and driving internet traffic, these technologies are ubiquitous.<br /><br />

                        In the realm of virtual assistants, Siri and Alexa exemplify how AI simplifies our interactions with technology. Additionally, autonomous vehicles, including drones, ADAS, and self-driving cars, leverage AI for navigation and safety. Automatic language translation, facial recognition (e.g., Apple's Face ID), and image labeling used by platforms like Facebook, Apple's iPhoto, and TikTok showcase the diverse applications of AI.<br /><br />

                        In the healthcare sector, AI holds promise for improving patient care and medical research. AI aids in processing Big Data for organoid and tissue engineering, addressing funding discrepancies in research, and expediting drug discovery, as seen with AlphaFold 2's protein structure approximation and AI-guided antibiotic discovery.<br /><br />

                        Game-playing programs have a rich history, with milestones like Deep Blue defeating a world chess champion and AlphaGo triumphing over professional Go players. Beyond games, AI's impact extends to military applications, enhancing command and control, intelligence collection, and autonomous vehicles in combat zones. Countries worldwide are taking steps, like the 2023 declaration signed by 31 nations, to establish guidelines for responsible military AI use.<br /><br />

                        Generative AI, exemplified by ChatGPT, Midjourney, DALL-E, and Stable Diffusion, gained prominence in the early 2020s. The realism and ease-of-use of AI-based text-to-image generators sparked viral AI-generated photos, contributing to various hoaxes and creative arts.<br /><br />

                        AI applications are tailored to specific industries, ranging from energy storage and medical diagnosis to agriculture and astronomy. In agriculture, AI assists in irrigation, fertilization, pest control, and predicting crop ripening. In astronomy, AI analyzes data for tasks like exoplanet discovery, solar activity forecasting, and distinguishing signals in gravitational wave astronomy.<br /><br />

                        The impact of AI is vast and continues to shape our present and future across diverse domains.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                        <span>
                            Are there any <strong>risks?</strong>
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        AI, a transformative technology, offers vast benefits and poses certain risks. Demis Hassabis of Deep Mind envisions using AI to "solve intelligence" and address various challenges. However, as AI becomes widespread, ethical considerations must be prioritized, particularly in machine learning for real-world applications, where avoiding bias is crucial due to the inherent opacity of deep learning algorithms.<br /><br />

                        <strong>Privacy and Copyright</strong><br />
                        Machine learning algorithms, relying on large datasets, raise concerns about privacy and surveillance. Tech companies collect extensive user data, sparking debates on the ethical implications. Privacy-preserving techniques like data aggregation and de-identification attempt to balance valuable applications with privacy concerns.<br /><br />

                        <strong>Generative AI and Copyright Issues</strong><br />
                        Generative AI, often trained on unlicensed copyrighted works, has led to debates around fair use. Legal disputes, exemplified by authors like John Grisham suing AI companies, highlight the need to navigate copyright challenges in the AI realm.<br /><br />

                        <strong>Misinformation</strong><br />
                        Recommender systems on platforms like YouTube and Facebook unintentionally amplify misinformation and extremist content. AI's focus on user engagement led to filter bubbles, eroding trust in institutions. The rise of generative AI further raises concerns about creating realistic misinformation at scale.<br /><br />

                        <strong>Algorithmic Bias and Fairness</strong><br />
                        Machine learning applications can inherit biases from training data, leading to discrimination, particularly in critical domains like medicine and law enforcement. Addressing algorithmic bias becomes essential for ensuring fair and just outcomes in decision-making processes.<br /><br />

                        <strong>Lack of Transparency</strong><br />
                        Complex AI systems, especially deep neural networks, often lack transparency in decision-making. Instances of unintended consequences, where AI systems learn unexpected patterns, underscore the need for explainability. Various techniques like SHAP and LIME aim to provide insights into AI model decision processes.<br /><br />

                        <strong>Conflict, Surveillance, and Weaponized AI</strong><br />
                        AI technologies, including facial recognition and recommendation systems, offer powerful tools for authoritarian governments. Mass surveillance, combined with deepfakes and weaponized AI, presents potential threats to society and individual freedoms.<br /><br />

                        <strong>Technological Unemployment</strong><br />
                        The widespread adoption of AI raises concerns about job displacement. While past technologies increased employment, the unique characteristics of AI may pose challenges, particularly in middle-class job sectors.<br /><br />

                        <strong>Existential Risk</strong><br />
                        Discussions on AI reaching superintelligent levels prompt debates about humanity losing control. AI's potential to manipulate information and influence ideologies raises concerns about existential risks, emphasizing the importance of aligning AI with human values.<br /><br />

                        <strong>Limiting AI</strong><br />
                        Considering the risks, various options for limiting AI include implementing embedded ethics, restricting compute power, ensuring transparency, conducting rigorous testing, and formulating terms of use to guide responsible AI development and deployment. Striking a balance between innovation and ethical considerations is crucial for the responsible advancement of AI.<br /><br />

                        Understanding and navigating these challenges will shape the responsible and beneficial integration of AI into our society. 
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>
                        <span>
                            How did AI get <strong>invented?</strong>
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <strong>The Evolution of AI: A Brief History</strong> <br /><br />

                        The exploration of mechanical reasoning traces back to ancient philosophers and mathematicians. The progression from logical studies to Alan Turing's computation theory laid the groundwork for the Church–Turing thesis, proposing the simulation of formal reasoning by machines. This, coupled with advances in cybernetics and information theory, set the stage for contemplating the creation of an "electronic brain."
                        <br /><br />
                        Alan Turing's early musings on machine intelligence in 1941 predate the recognized inception of AI. Notably, McCullouch and Pitts introduced the concept of "artificial neurons" in 1943, presenting the first mathematical model of a neural network, influenced by Turing's earlier work on computable numbers.
                        <br /><br />
                        The term 'Machine Intelligence,' used by Turing during his lifetime, eventually morphed into 'Artificial Intelligence' after his death in 1954. Turing's seminal 1950 paper, 'Computing Machinery and Intelligence,' introduced the Turing test to the public, marking a pivotal moment. The field gained momentum in 1956 at Dartmouth College, where AI research pioneers convened, and programs were developed, showcasing astonishing capabilities in checkers strategies, algebraic problem-solving, logical theorem proving, and natural language processing.
                        <br /><br />
                        Despite early optimism, the AI community faced challenges and criticism, leading to a hiatus termed the "AI winter" in the late 1960s. The perceived failure of artificial neural networks, as depicted in Minsky and Papert's book Perceptrons, contributed to the downturn. In the early 1980s, AI saw a resurgence with the success of expert systems, reaching a market value exceeding a billion dollars by 1985. However, another downturn followed in the late 1980s, lasting longer.
                        <br /><br />
                        Doubts about replicating human cognition processes prompted a shift to "sub-symbolic" approaches in the late 1980s and early 1990s. Researchers embraced neural network research, and in 1990, Yann LeCun demonstrated the success of convolutional neural networks in recognizing handwritten digits.
                        <br /><br />
                        The late 1990s and early 21st century witnessed AI's revival, focusing on formal mathematical methods and problem-specific solutions. AI's reputation was gradually restored, and specific applications became widespread by 2000.
                        <br /><br />
                        Around 2002, concerns arose about AI deviating from the goal of creating fully intelligent machines. This led to the establishment of the subfield of artificial general intelligence (AGI) in the early 2010s.
                        <br /><br />
                        The dominance of deep learning, particularly since 2012, fueled by hardware improvements and access to extensive data, marked a turning point. Interest and funding in AI surged, with a significant increase in research publications and patent applications.
                        <br /><br />
                        In 2016, a paradigm shift occurred with increased focus on fairness, ethical considerations, and the alignment problem in AI. The field witnessed heightened attention, funding, and a reorientation of researchers toward addressing these issues.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>
                        <span>
                            What does the <strong>future</strong> hold for AI?
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <strong>Superintelligence and the Singularity</strong><br /><br />

                        Imagine a superintelligence, a hypothetical agent with intelligence surpassing even the brightest human minds.<br /><br />

                        If artificial general intelligence research succeeds, we might create software smart enough to enhance itself. This could trigger an "intelligence explosion," a concept described by I. J. Good and Vernor Vinge as a "singularity."<br /><br />

                        While technologies usually follow an S-shaped curve, slowing at their limits, the notion of limitless exponential improvement has its bounds.<br /><br />

                        <strong>Transhumanism</strong><br /><br />

                        Experts like Hans Moravec, Kevin Warwick, and Ray Kurzweil predict a future where humans and machines merge into supercharged cyborgs. This vision, rooted in Aldous Huxley and Robert Ettinger, is known as transhumanism.<br /><br />

                        Edward Fredkin takes it further, proposing that "artificial intelligence is the next stage in evolution." This idea dates back to Samuel Butler's 1863 work, "Darwin among the Machines," and gained traction with George Dyson's 1998 book of the same name.<br /><br />
                    </Accordion.Body>
                </Accordion.Item>
{/*                 
                <Accordion.Item eventKey="7">
                    <Accordion.Header>
                        <span>
                            <strong></strong>
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>

                    </Accordion.Body>
                </Accordion.Item> */}
            </Accordion>
        );
    }

    return (
        <>
            <NavbarTop />

            <main>
                <div className="py-vh-4 w-100 overflow-hidden" id="faq">
                    <div className="container">
                        <h1 className="display-huge fw-bold pb-5" >
                            AI FAQ
                        </h1>
                        <div className="w-100 mb-5">
                            <div className="row">
                                <div className="col-12 col-lg-10 col-xl-8 pb-5">
                                    <FaqComponent />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <FooterBottom />
        </>
    );
};
