// GuidePage.js
import React, { useEffect } from 'react';
import NavbarTop from './NavbarTop';
import FooterBottom from './FooterBottom';
import { Helmet } from "react-helmet";
import { FaLightbulb, FaPlus, FaMicrochip, FaArrowRight, FaBrain, FaHammer, FaPeopleGroup, FaAward, FaHandSpock, FaBook, FaQuestion, FaList } from "react-icons/fa6";
import Gumshoe from 'gumshoejs';

export default function GuidePage() {
    
    useEffect(() => {    
        /* ===== Gumshoe SrollSpy ===== */
        /* Ref: https://github.com/cferdinandi/gumshoe  */
        // Initialize Gumshoe
        var spy = new Gumshoe('#docs-nav a', {
            offset: 70, // header height
        });
    }, []); // Empty dependency array to run the effect only once on mount
    



    // ai guide data to display, id starting with 1
    const guideData = [
        {
            id: 1,
            title: 'Artificial Intelligence',
            icon: <FaMicrochip />,
            header: 
                <>
                    <section className="docs-intro">
                        <p>
                            Artificial Intelligence (AI) refers to the simulation of human intelligence in machines that are programmed to think and mimic human actions. The goal of AI is to develop systems that can perform tasks that typically require human intelligence, such as problem-solving, decision-making, learning, and perception.
                        </p>
                    </section>
                </>,
            subsections: 
            [
                {
                    subtitle: 'Definition of AI',
                    data:
                        <>
                            <p>
                                AI encompasses a wide range of technologies and techniques aimed at creating intelligent systems capable of reasoning, understanding natural language, learning from experience, and adapting to new situations. These systems can be classified into various categories, including narrow AI, which is designed for specific tasks, and general AI, which aims to replicate the full spectrum of human cognitive abilities.
                            </p>	
                        </>
                },
                {
                    subtitle: 'Brief History of AI',
                    data: 
                        <>
                            <p>
                                The concept of artificial intelligence dates back to ancient times, with early myths and legends depicting artificial beings endowed with human-like qualities. However, the formal study of AI as a scientific discipline began in the mid-20th century. In 1956, the term "artificial intelligence" was coined at the Dartmouth Conference, marking the birth of the field.
                                <br /><br />
                                Over the decades, AI has seen significant advancements driven by breakthroughs in computer science, mathematics, and cognitive psychology. Key milestones include the development of expert systems in the 1970s, the emergence of machine learning techniques in the 1980s, and the rise of deep learning and neural networks in the 21st century.
                            </p>
                        </>
                },
                {
                    subtitle: 'Importance of AI in Modern Society',
                    data: 
                        <>
                            <p>
                                AI has become an integral part of modern society, revolutionizing various industries and sectors. From healthcare and finance to transportation and entertainment, AI technologies are transforming the way we live, work, and interact with the world around us.
                                <br /><br />
                                In healthcare, AI is being used to diagnose diseases, personalize treatment plans, and improve patient outcomes. In finance, AI-powered algorithms are employed for fraud detection, risk assessment, and automated trading. In transportation, AI plays a crucial role in autonomous vehicles, traffic management, and logistics optimization.
                                <br /><br />
                                Furthermore, AI is driving innovation in fields such as education, agriculture, manufacturing, and environmental conservation. Its potential to streamline processes, enhance productivity, and unlock new possibilities makes AI one of the most transformative technologies of the 21st century.
                            </p>
                        </>
                }
            ],
        },
        {
            id: 2,
            title: 'Types of AI',
            icon: <FaList />,
            header: 
                <>
                    <section className="docs-intro">
                        <p>
                            Artificial Intelligence (AI) can be categorized into different types based on the level of intelligence and capabilities exhibited by the systems. These types range from specialized systems designed for specific tasks to hypothetical systems with superhuman intelligence.
                        </p>
                    </section>
                </>,
            subsections: 
            [
                {
                    subtitle: 'Narrow AI',
                    data: 
                    <>
                        <p>
                            Narrow AI, also known as weak AI or specialized AI, refers to AI systems that are designed and trained for a specific task or narrow range of tasks. These systems excel at performing well-defined functions within a limited domain but lack the broad cognitive abilities of human intelligence. Examples of narrow AI include virtual assistants like Siri and Alexa, recommendation systems used in e-commerce platforms, and image recognition algorithms used in facial recognition software.
                        </p>
                    </>
                },
                {
                    subtitle: 'General AI',
                    data: 
                    <>
                        <p>
                            General AI, also known as strong AI or artificial general intelligence (AGI), refers to AI systems that possess the ability to understand, learn, and apply knowledge across a wide range of tasks and domains, similar to human intelligence. Unlike narrow AI, which is specialized in specific tasks, general AI is capable of reasoning, problem-solving, and adapting to novel situations in a manner that mirrors human intelligence. Achieving true general AI remains a long-term goal of AI research and development, with significant technical and ethical challenges to overcome.
                        </p>
                    </>
                },
                {
                    subtitle: 'Superintelligent AI',
                    data: 
                    <>
                        <p>
                            Superintelligent AI refers to hypothetical AI systems that surpass human intelligence in virtually every aspect. These systems, if realized, would possess cognitive abilities far beyond those of the smartest humans and could potentially outperform humans in any intellectual task. Superintelligent AI poses profound implications for society, raising concerns about existential risks, ethical dilemmas, and the control and governance of such powerful systems. While superintelligent AI remains speculative, researchers and policymakers are actively exploring ways to ensure the safe and responsible development of AI technologies.
                        </p>
                    </>
                },
            ]
        },
        {
            id: 3,
            title: 'Machine Learning',
            icon: <FaLightbulb />,
            header: 
                <>
                    <section className="docs-intro">
                        <p>
                            Artificial Intelligence (AI) encompasses a diverse set of techniques and approaches aimed at creating intelligent systems capable of learning, reasoning, and solving complex problems. Among the most prominent techniques are those falling under the umbrella of machine learning, which enable systems to learn from data and improve their performance over time.
                            <br /><br />
                            Machine learning is a subset of AI that focuses on developing algorithms and models capable of learning from data and making predictions or decisions without being explicitly programmed. It enables computers to recognize patterns, identify trends, and extract meaningful insights from large datasets. There are several types of machine learning techniques, including supervised learning, unsupervised learning, and reinforcement learning.                        </p>
                    </section>
                </>,
            subsections: 
            [
                {
                    subtitle: 'Supervised learning',
                    data: 
                    <>
                        <p>
                        Supervised learning is a type of machine learning where the model is trained on labeled data, meaning that each input data point is paired with a corresponding target output. During training, the model learns to map input data to the correct output by minimizing the error between its predictions and the actual labels. Supervised learning algorithms are commonly used for tasks such as classification, regression, and ranking. Examples of supervised learning algorithms include support vector machines (SVM), decision trees, and neural networks.                        </p>
                    </>
                },
                {
                    subtitle: 'Unsupervised learning',
                    data: 
                    <>
                        <p>
                        Unsupervised learning is a type of machine learning where the model is trained on unlabeled data, meaning that the input data is not accompanied by corresponding target outputs. Instead, the model learns to identify patterns, clusters, and structures within the data without explicit guidance. Unsupervised learning algorithms are commonly used for tasks such as clustering, dimensionality reduction, and anomaly detection. Examples of unsupervised learning algorithms include k-means clustering, principal component analysis (PCA), and autoencoders.                        </p>
                    </>
                },
                {
                    subtitle: 'Reinforcement learning',
                    data: 
                    <>
                        <p>
                        Reinforcement learning is a type of machine learning where an agent learns to interact with an environment in order to maximize a cumulative reward signal. The agent takes actions in the environment, receives feedback in the form of rewards or penalties, and learns to adjust its behavior to achieve long-term goals. Reinforcement learning algorithms are commonly used in settings where the agent's actions influence future states of the environment, such as game playing, robotics, and autonomous vehicle control. Examples of reinforcement learning algorithms include Q-learning, deep Q-networks (DQN), and policy gradients.
                        <br /><br />
                        These machine learning techniques form the foundation of many AI applications and are instrumental in enabling systems to acquire knowledge, improve performance, and adapt to changing environments.
                        </p>
                    </>
                },
            ]
        },
        {
            id: 4,
            title: 'Deep Learning',
            icon: <FaBrain />,
            header: 
                <>
                    <section className="docs-intro">
                        <p>
                            Artificial Intelligence (AI) continues to evolve rapidly, with deep learning emerging as a powerful subset of machine learning that has revolutionized various fields. Deep learning techniques, inspired by the structure and function of the human brain, enable AI systems to learn complex patterns and representations from raw data.
                            <br /><br />
                            Deep learning is a class of machine learning algorithms that utilize neural networks with multiple layers (hence the term "deep") to extract high-level features from raw data. These neural networks are composed of interconnected nodes, or neurons, organized into layers that process input data and generate output predictions. Deep learning has achieved remarkable success in tasks such as image recognition, natural language processing, and speech recognition, surpassing traditional machine learning approaches in many domains.
                        </p>
                    </section>
                </>,
            subsections: 
            [
                {
                    subtitle: 'Neural Networks',
                    data: 
                    <>
                        <p>
                        Neural networks are the fundamental building blocks of deep learning systems. They consist of interconnected layers of neurons, each layer performing a specific computation on the input data. Neural networks are trained using a process called backpropagation, where the model adjusts its internal parameters (weights and biases) based on the error between its predictions and the true labels in the training data. This iterative process enables neural networks to learn complex mappings between input and output data, making them highly effective for tasks such as classification, regression, and pattern recognition.
                        </p>
                    </>
                },
                {
                    subtitle: 'Convolutional Neural Networks',
                    data: 
                    <>
                        <p>
                        Convolutional Neural Networks (CNNs) are a specialized type of neural network designed for processing structured grid-like data, such as images and videos. CNNs employ convolutional layers, which apply filters to input data to extract spatial hierarchies of features. These hierarchical representations enable CNNs to capture local patterns and spatial relationships within images, making them well-suited for tasks such as object detection, image segmentation, and image classification. CNNs have achieved state-of-the-art performance in computer vision tasks and are widely used in applications ranging from autonomous driving to medical imaging.
                        </p>
                    </>
                },
                {
                    subtitle: 'Recurrent Neural Networks',
                    data: 
                    <>
                        <p>
                            Recurrent Neural Networks (RNNs) are a class of neural networks designed for processing sequential data, such as time-series data or natural language sequences. Unlike feedforward neural networks, which process input data in a single pass, RNNs have recurrent connections that allow them to maintain internal state and capture temporal dependencies across sequential inputs. This makes RNNs particularly effective for tasks such as speech recognition, language translation, and text generation. However, traditional RNNs suffer from limitations such as vanishing gradients and difficulty in capturing long-term dependencies. To address these issues, variants of RNNs such as Long Short-Term Memory (LSTM) networks and Gated Recurrent Units (GRUs) have been developed, which incorporate mechanisms for better handling long-range dependencies and preserving information over time.
                            <br /><br />
                            Deep learning techniques, including neural networks, CNNs, and RNNs, have propelled the field of AI forward, enabling unprecedented levels of performance in tasks ranging from image recognition to natural language understanding. As research in deep learning continues to advance, the potential applications and impact of these techniques are boundless.
                        </p>
                    </>
                },
            ]
        },
        {
            id: 5,
            title: 'Other Techniques',
            icon: <FaPlus />,
            header: 
                <>
                    <section className="docs-intro">
                        <p>
                            In addition to machine learning and deep learning, artificial intelligence (AI) encompasses a diverse array of techniques and approaches tailored to specific problem domains and applications. These techniques leverage advanced algorithms and methodologies to enable AI systems to understand, process, and interpret different types of data, ranging from text and images to structured data and expert knowledge.
                        </p>
                    </section>
                </>,
            subsections: 
            [
                {
                    subtitle: 'Natural Language Processing',
                    data: 
                    <>
                        <p>
                            Natural Language Processing (NLP) is a branch of AI focused on enabling computers to understand, interpret, and generate human language. NLP techniques encompass a wide range of tasks, including text classification, sentiment analysis, machine translation, speech recognition, and text generation. NLP algorithms leverage techniques from machine learning, deep learning, and linguistics to extract meaning from textual data and enable machines to communicate with humans in a natural and intuitive manner.
                        </p>
                    </>
                },
                {
                    subtitle: 'Computer Vision',
                    data: 
                    <>
                        <p>
                            Computer Vision is a field of AI concerned with enabling computers to interpret and analyze visual information from the real world. Computer vision techniques allow AI systems to understand and interpret images and videos, extract meaningful features, and make sense of visual data. Applications of computer vision include object detection, image segmentation, facial recognition, autonomous navigation, and medical image analysis. Computer vision algorithms often leverage deep learning techniques, such as convolutional neural networks (CNNs), to achieve state-of-the-art performance in visual recognition tasks.
                        </p>
                    </>
                },
                {
                    subtitle: 'Genetic Algorithms',
                    data: 
                    <>
                        <p>
                            Genetic Algorithms are a class of optimization algorithms inspired by the principles of natural selection and evolution. Genetic algorithms mimic the process of natural evolution by iteratively generating a population of candidate solutions, evaluating their fitness based on a predefined objective function, and selecting the fittest individuals for reproduction and mutation to produce the next generation of solutions. Genetic algorithms are particularly useful for solving optimization problems with complex, nonlinear search spaces and have been applied in areas such as engineering design, scheduling, and resource allocation.
                        </p>
                    </>
                },
                {
                    subtitle: 'Swarm Intelligence',
                    data: 
                    <>
                        <p>
                            Swarm Intelligence is a collective intelligence paradigm inspired by the collective behavior of social insects, such as ants, bees, and termites. Swarm intelligence algorithms model the interactions and cooperation among individual agents within a swarm to solve complex problems and achieve global optimization. Examples of swarm intelligence algorithms include ant colony optimization (ACO), particle swarm optimization (PSO), and artificial bee colony (ABC) optimization. Swarm intelligence techniques have been applied in various domains, including optimization, routing, clustering, and robotics, to solve complex problems and adapt to dynamic environments.
                            <br /><br />
                            These AI techniques and approaches, including natural language processing, computer vision, expert systems, genetic algorithms, and swarm intelligence, enable AI systems to tackle a wide range of tasks and domains, driving innovation and advancements in various fields. As research in AI continues to evolve, the integration and combination of these techniques hold promise for solving increasingly complex problems and pushing the boundaries of artificial intelligence.
                        </p>
                    </>
                },
            ]
        },
        {
            id: 6,
            title: 'Applications',
            icon: <FaHammer />,
            header: 
                <>
                    <section className="docs-intro">
                        <p>
                            Artificial Intelligence (AI) has emerged as a transformative technology with widespread applications across various industries and sectors. From healthcare to finance, and from automotive to retail, AI-driven solutions are revolutionizing traditional practices, enhancing efficiency, and driving innovation. Below are some key domains where AI is making a significant impact:                        </p>
                    </section>
                </>,
            subsections: 
            [
                {
                    subtitle: 'Healthcare',
                    data: 
                    <>
                        <p>
                            <ul>
                                <li>
                                    <strong>Diagnosis and medical imaging</strong>
                                    <br /><br />
                                    AI-powered systems assist healthcare professionals in diagnosing diseases and interpreting medical images, such as X-rays, MRI scans, and CT scans, leading to earlier detection and better treatment outcomes.
                                </li>
                                <li>
                                    <strong>Drug discovery and development</strong>
                                    <br /><br />
                                    AI algorithms accelerate the drug discovery process by analyzing large datasets, predicting molecular interactions, and identifying potential drug candidates for various diseases.
                                </li>
                                <li>
                                    <strong>Personalized medicine</strong>
                                    <br /><br />
                                    AI-based predictive analytics enable personalized treatment plans tailored to individual patients' genetic makeup, medical history, and lifestyle factors, improving patient care and outcomes.
                                </li>
                            </ul>
                        </p>
                    </>
                },
                {
                    subtitle: 'Finance',
                    data: 
                    <>
                        <p>
                            <ul>
                                <li>
                                    <strong>Fraud detection and risk management</strong>
                                    <br /><br />
                                    AI algorithms analyze transactional data in real-time to detect fraudulent activities and mitigate financial risks, safeguarding organizations and customers against fraudulent transactions.
                                </li>
                                <li>
                                    <strong>Algorithmic trading</strong>
                                    <br /><br />
                                    AI-driven trading systems leverage machine learning algorithms to analyze market trends, identify trading opportunities, and execute trades at high speeds, optimizing investment strategies and generating profits.
                                </li>
                                <li>
                                    <strong>Customer service and chatbots</strong>
                                    <br /><br />
                                    AI-powered chatbots and virtual assistants provide personalized customer support, automate routine tasks, and streamline interactions in banking, insurance, and other financial services.                                </li>
                            </ul>
                        </p>
                    </>
                },
                {
                    subtitle: 'Automotive Industry',
                    data: 
                    <>
                        <p>
                            <ul>
                                <li>
                                    <strong>Autonomous vehicles</strong>
                                    <br /><br />
                                    AI technologies enable self-driving cars to perceive their environment, make real-time decisions, and navigate safely on roads, reducing accidents, congestion, and emissions.
                                </li>
                                <li>
                                    <strong>Predictive maintenance</strong>
                                    <br /><br />
                                    AI-based predictive analytics monitor vehicle health, detect potential faults or malfunctions, and schedule maintenance proactively, minimizing downtime and optimizing fleet operations.
                                </li>
                                <li>
                                    <strong>Intelligent transportation systems</strong>
                                    <br /><br />
                                    AI algorithms optimize traffic flow, manage congestion, and enhance safety on roads through real-time monitoring, adaptive signal control, and route optimization.                                </li>
                            </ul>
                        </p>
                    </>
                },
                {
                    subtitle: 'Retail',
                    data: 
                    <>
                        <p>
                            <ul>
                                <li>
                                    <strong>Personalized recommendations</strong>
                                    <br /><br />
                                    AI-driven recommendation systems analyze customer preferences, purchase history, and behavior to deliver personalized product recommendations, enhancing the shopping experience and increasing sales.
                                </li>
                                <li>
                                    <strong>Inventory management</strong>
                                    <br /><br />
                                    AI algorithms optimize inventory levels, forecast demand, and prevent stockouts or overstock situations, improving supply chain efficiency and reducing costs.
                                </li>
                                <li>
                                    <strong>Visual search and image recognition</strong>
                                    <br /><br />
                                    AI-powered visual search engines enable customers to search for products using images, enhancing product discovery and facilitating seamless shopping experiences.
                                </li>
                            </ul>
                        </p>
                    </>
                },
                {
                    subtitle: 'Agriculture',
                    data: 
                    <>
                        <p>
                            <ul>
                                <li>
                                    <strong>Precision agriculture</strong>
                                    <br /><br />
                                    AI-based solutions analyze satellite imagery, sensor data, and weather forecasts to optimize crop management practices, monitor soil health, and maximize yields while minimizing resource usage.
                                </li>
                                <li>
                                    <strong>Crop disease detection</strong>
                                    <br /><br />
                                    AI algorithms analyze images of crops to identify signs of diseases, pests, or nutrient deficiencies, enabling early intervention and targeted treatment to prevent crop losses.
                                </li>
                                <li>
                                    <strong>Autonomous farm equipment</strong>
                                    <br /><br />
                                    AI-driven autonomous drones and robots perform tasks such as planting, harvesting, and spraying pesticides with precision and efficiency, improving productivity and reducing labor costs.                                </li>
                            </ul>
                        </p>
                    </>
                },
                {
                    subtitle: 'Gaming',
                    data: 
                    <>
                        <p>
                            <ul>
                                <li>
                                    <strong>AI opponents and NPCs</strong>
                                    <br /><br />
                                    AI algorithms power intelligent non-player characters (NPCs) and opponents in video games, providing challenging gameplay experiences, adapting to player behavior, and enhancing immersion.
                                </li>
                                <li>
                                    <strong>Procedural content generation</strong>
                                    <br /><br />
                                    AI techniques generate game content such as levels, maps, characters, and narratives dynamically, enhancing replayability and reducing development time and costs.                                </li>
                            </ul>
                        </p>
                    </>
                },
                {
                    subtitle: 'Robotics',
                    data: 
                    <>
                        <p>
                            <ul>
                                <li>
                                    <strong>Industrial automation</strong>
                                    <br /><br />
                                    AI-driven robots perform repetitive tasks, assembly line operations, and quality control inspections with precision and speed, improving productivity and safety in manufacturing environments.
                                </li>
                                <li>
                                    <strong>Healthcare robotics</strong>
                                    <br /><br />
                                    AI-powered robots assist surgeons in performing minimally invasive surgeries, provide physical therapy to patients, and automate tasks in hospitals and healthcare facilities, enhancing patient care and reducing medical errors.
                                </li>
                            </ul>
                        </p>
                    </>
                },
                {
                    subtitle: 'Smart Cities',
                    data: 
                    <>
                        <p>
                            <ul>
                                <li>
                                    <strong>Traffic management</strong>
                                    <br /><br />
                                    AI algorithms optimize traffic flow, reduce congestion, and improve road safety through real-time monitoring, predictive analytics, and adaptive traffic signal control systems.
                                </li>
                                <li>
                                    <strong>Energy management</strong>
                                    <br /><br />
                                    AI-driven smart grids optimize energy distribution, predict demand patterns, and integrate renewable energy sources efficiently, reducing energy consumption and carbon emissions.
                                </li>
                                <li>
                                    <strong>Public safety and security</strong>
                                    <br /><br />
                                    AI-powered surveillance systems monitor public spaces, analyze video feeds, and detect suspicious activities or emergencies in real-time, enhancing public safety and emergency response capabilities.
                                </li>
                            </ul>

                            These are just a few examples of how AI is transforming industries and driving innovation across diverse domains. As AI technologies continue to advance, their potential to solve complex problems and create value for businesses, governments, and society at large will only continue to grow.

                        </p>
                    </>
                },
            ]
        },
        {
            id: 7,
            title: 'Ethical & Societal Implications',
            icon: <FaPeopleGroup />,
            header: 
                <>
                    <section className="docs-intro">
                        <p>
                        As Artificial Intelligence (AI) technologies continue to advance and permeate various aspects of society, there are growing concerns about their ethical and societal implications. While AI holds immense promise for improving efficiency, productivity, and quality of life, it also raises critical ethical questions and challenges that must be addressed to ensure its responsible and equitable deployment.                        </p>
                    </section>
                </>,
            subsections: 
            [
                {
                    subtitle: 'Job Displacement',
                    data: 
                    <>
                        <p>
                            Automation and AI-driven technologies have the potential to disrupt labor markets, leading to job displacement and economic dislocation in certain industries and occupations.
                            Low-skilled and routine tasks are particularly vulnerable to automation, raising concerns about unemployment, income inequality, and the need for workforce reskilling and retraining programs to mitigate the impact of job loss.
                        </p>
                    </>
                },
                {
                    subtitle: 'Bias in AI Systems',
                    data: 
                    <>
                        <p>
                            AI systems are susceptible to bias and discrimination, as they often reflect the biases present in the data used for training and the design decisions made by developers.
                            Bias in AI systems can lead to unfair or discriminatory outcomes, perpetuate existing societal inequalities, and undermine trust in AI-powered applications across various domains, including hiring, lending, criminal justice, and healthcare.
                        </p>
                    </>
                },
                {
                    subtitle: 'Privacy Concerns',
                    data: 
                    <>
                        <p>
                            The widespread adoption of AI technologies, particularly in areas such as surveillance, data analytics, and personalization, raises concerns about privacy infringement and data misuse.
                            AI-powered systems often rely on vast amounts of personal data to train algorithms and make predictions, raising questions about data ownership, consent, transparency, and the need for robust data protection regulations and privacy-preserving technologies.
                        </p>
                    </>
                },
                {
                    subtitle: 'Autonomous Weapons',
                    data: 
                    <>
                        <p>
                            The development and deployment of autonomous weapons systems, including lethal autonomous weapons (LAWs), raise profound ethical questions and risks related to accountability, transparency, and the potential for unintended harm.
                            Concerns have been raised about the moral and legal implications of delegating life-and-death decisions to AI systems, the risk of autonomous weapons being used for malicious purposes, and the need for international agreements to regulate their use.
                        </p>
                    </>
                },
                {
                    subtitle: 'AI Governance and Regulations',
                    data: 
                    <>
                        <p>
                            The rapid advancement of AI technologies has outpaced the development of regulatory frameworks and governance mechanisms to ensure their responsible and ethical use.
                            There is a growing need for AI governance frameworks, standards, and regulations to address issues such as accountability, transparency, fairness, safety, and the ethical use of AI across different sectors and applications.
                            Stakeholders, including governments, industry leaders, researchers, ethicists, and civil society organizations, must collaborate to develop and implement AI governance mechanisms that balance innovation with ethical considerations and promote the responsible deployment of AI technologies.
                            <br /><br />
                            Addressing the ethical and societal implications of AI requires a multifaceted approach involving policymakers, industry stakeholders, researchers, and the public. By fostering dialogue, collaboration, and responsible innovation, we can harness the potential of AI to benefit society while mitigating its risks and ensuring that AI technologies serve the common good.       
                        </p>
                    </>
                },
            ]
        },
        {
            id: 8,
            title: 'Challenges and Future',
            icon: <FaArrowRight />,
            header: 
                <>
                    <section className="docs-intro">
                        <p>
                            While Artificial Intelligence (AI) holds immense promise for transforming industries and improving various aspects of human life, it also presents significant challenges and raises important questions about its future trajectory. Addressing these challenges will be crucial for realizing the full potential of AI while ensuring its responsible and ethical development.
                        </p>
                    </section>
                </>,
            subsections: 
            [
                {
                    subtitle: 'AGI and Superintelligence',
                    data: 
                    <>
                        <p>
                            Achieving Artificial General Intelligence (AGI), or AI systems with human-level intelligence across a wide range of tasks, remains a long-term goal of AI research.
                            The prospect of superintelligent AI, or AI systems surpassing human intelligence in virtually every aspect, raises profound ethical, existential, and societal implications, including concerns about control, safety, and the potential for unintended consequences.
                        </p>
                    </>
                },
                {
                    subtitle: 'Ethical AI Development',
                    data: 
                    <>
                        <p>
                            Ensuring the ethical development and deployment of AI technologies is paramount to mitigating risks and safeguarding against potential harms.
                            Ethical considerations include fairness, accountability, transparency, privacy, bias mitigation, and the impact of AI on societal values, norms, and human rights.
                        </p>
                    </>
                },
                {
                    subtitle: 'Data Privacy and Security',
                    data: 
                    <>
                        <p>
                            Protecting data privacy and ensuring cybersecurity are critical challenges in the era of AI, as AI systems rely heavily on vast amounts of data for training and decision-making.
                            Safeguarding sensitive personal information, preventing data breaches, and addressing privacy concerns are essential for building trust in AI-powered applications and preserving individuals' rights to privacy.
                        </p>
                    </>
                },
                {
                    subtitle: 'AI and Creativity',
                    data: 
                    <>
                        <p>
                            Exploring the intersection of AI and creativity raises intriguing questions about the nature of intelligence, consciousness, and the potential for machines to exhibit creative behaviors.
                            While AI systems have demonstrated remarkable capabilities in generating art, music, literature, and other creative works, questions remain about the authenticity, originality, and emotional depth of AI-generated content.
                        </p>
                    </>
                },
                {
                    subtitle: 'Human-AI Collaboration',
                    data: 
                    <>
                        <p>
                            Fostering effective collaboration between humans and AI systems is essential for realizing the full potential of AI while leveraging human expertise, creativity, and judgment.
                            Human-AI collaboration models, such as augmented intelligence and human-in-the-loop systems, enable humans and AI to complement each other's strengths and compensate for their respective limitations.
                            <br /><br />
                            Addressing these challenges and shaping the future of AI will require collaboration and coordination among researchers, policymakers, industry stakeholders, ethicists, and civil society organizations. By prioritizing ethical considerations, promoting transparency, fostering innovation, and embracing responsible AI development practices, we can harness the transformative power of AI to address complex societal challenges, enhance human well-being, and create a more equitable and sustainable future.
                        </p>
                    </>
                },
            ]
        },
        {
            id: 9,
            title: 'Notable Figures in AI',
            icon: <FaAward />,
            header: 
                <>
                    <section className="docs-intro">
                        <p>
                            In the dynamic landscape of Artificial Intelligence (AI), several luminaries have played pivotal roles in shaping its evolution, driving groundbreaking research, and pushing the boundaries of what's possible. From trailblazing theorists to innovative practitioners, these notable figures have left an indelible mark on the field, inspiring generations of researchers and enthusiasts alike.
                        </p>
                    </section>
                </>,
            subsections: 
            [
                {
                    subtitle: 'Alan Turing',
                    data: 
                    <>
                        <p>
                            <strong>The Father of Computer Science and AI</strong><br />
                            Alan Turing's contributions to AI and computer science are legendary. His groundbreaking work laid the foundation for modern computing and AI, most notably with the Turing Machine, a theoretical device that became the basis for modern computers. Turing's pioneering ideas about machine intelligence, including the famous Turing Test, continue to influence AI research and philosophy to this day.
                        </p>
                    </>
                },
                {
                    subtitle: 'John McCarthy',
                    data: 
                    <>
                        <p>
                            <strong>The Architect of AI</strong><br />
                            John McCarthy is widely regarded as one of the founding fathers of AI. He coined the term "artificial intelligence" and organized the seminal Dartmouth Conference in 1956, which marked the birth of the AI field. McCarthy's research laid the groundwork for key AI techniques such as symbolic reasoning and logic-based approaches, paving the way for the development of expert systems and knowledge-based AI.
                        </p>
                    </>
                },
                {
                    subtitle: 'Marvin Minsky',
                    data: 
                    <>
                        <p>
                            <strong>The Mind Behind Cognitive Science</strong><br />
                            Marvin Minsky was a towering figure in the field of cognitive science and AI. His pioneering work on artificial neural networks and cognitive architectures helped shape the early foundations of AI research. Minsky's seminal book "Perceptrons," co-authored with Seymour Papert, explored the limitations of early neural network models and laid the groundwork for future advances in deep learning and connectionist approaches.
                        </p>
                    </>
                },
                {
                    subtitle: 'Geoffrey Hinton',
                    data: 
                    <>
                        <p>
                            <strong>The Godfather of Deep Learning</strong><br />
                            Geoffrey Hinton's contributions to deep learning have revolutionized the field of AI. His groundbreaking work on backpropagation and Boltzmann machines paved the way for the resurgence of neural networks in the 21st century. Hinton's research laid the foundation for modern deep learning techniques, including convolutional neural networks (CNNs) and recurrent neural networks (RNNs), which have achieved remarkable success in areas such as computer vision, natural language processing, and speech recognition.
                        </p>
                    </>
                },
                {
                    subtitle: 'Yoshua Bengio',
                    data: 
                    <>
                        <p>
                            <strong>The Torchbearer of Deep Learning Research</strong><br />
                            Yoshua Bengio is a pioneering researcher in the field of deep learning and neural networks. His influential work on deep learning architectures, including deep belief networks and generative adversarial networks (GANs), has significantly advanced the state-of-the-art in AI. Bengio's research has had a profound impact on various applications of AI, including speech recognition, image understanding, and reinforcement learning.

                        </p>
                    </>
                },
                {
                    subtitle: 'Andrew Ng',
                    data: 
                    <>
                        <p>
                            <strong>The Advocate for AI Education and Accessibility</strong><br />
                            Andrew Ng is a leading figure in the AI community and a passionate advocate for AI education and accessibility. As co-founder of Google Brain and former Chief Scientist at Baidu, Ng has played a key role in advancing AI research and technology. He is also the co-founder of Coursera, an online platform that offers courses on AI and machine learning, making these topics accessible to learners worldwide.
                            <br /><br />
                            These notable figures have not only made significant contributions to the field of AI but have also inspired countless individuals to pursue careers in AI research, education, and entrepreneurship. Their visionary ideas, pioneering research, and tireless advocacy have helped shape the trajectory of AI and continue to drive innovation and progress in the quest for artificial intelligence.
                        </p>
                    </>
                },
            ]
        },
        {
            id: 10,
            title: 'Popular Culture',
            icon: <FaHandSpock />,
            header: 
                <>
                    <section className="docs-intro">
                        <p>
                            Artificial Intelligence (AI) has captivated the human imagination for decades, inspiring a plethora of creative works across various forms of media. From the silver screen to the pages of novels, and from virtual worlds to the realms of music and art, AI's portrayal in popular culture reflects society's fascination, fears, and fantasies about the potential of intelligent machines.
                        </p>
                    </section>
                </>,
            subsections: 
            [
                {
                    subtitle: 'Movies and Literature',
                    data: 
                    <>
                        <p>
                            AI has been a recurring theme in movies and literature, often exploring complex questions about humanity, consciousness, and the nature of intelligence. From Isaac Asimov's "I, Robot" to Philip K. Dick's "Do Androids Dream of Electric Sheep?" and Arthur C. Clarke's "2001: A Space Odyssey," literary works have grappled with the ethical and existential implications of AI. Similarly, iconic films such as "Blade Runner," "The Terminator," and "Ex Machina" have brought AI to the forefront of popular culture, presenting compelling narratives that explore the blurred boundaries between man and machine, creator and creation.
                        </p>
                    </>
                },
                {
                    subtitle: 'Video Games',
                    data: 
                    <>
                        <p>
                            Video games have long embraced AI as a fundamental component of gameplay, enabling dynamic and immersive experiences for players. From intelligent NPCs (non-player characters) that exhibit lifelike behaviors and adapt to player actions to sophisticated enemy AI that pose formidable challenges, AI algorithms bring virtual worlds to life and shape the narratives of interactive storytelling. Games like "The Sims," "Fallout," and "Half-Life" have pushed the boundaries of AI in gaming, showcasing the potential for emergent gameplay and player-driven experiences in virtual environments.
                        </p>
                    </>
                },
                {
                    subtitle: 'Music and Art',
                    data: 
                    <>
                        <p>
                            AI's influence extends beyond the realm of science fiction and gaming into the worlds of music and art, where it serves as a catalyst for creative exploration and experimentation. AI algorithms, such as generative adversarial networks (GANs) and recurrent neural networks (RNNs), are being used to compose music, generate visual art, and even create original works of literature. Projects like Google's Magenta and OpenAI's MuseNet are pushing the boundaries of AI creativity, inspiring artists and musicians to collaborate with intelligent machines and explore new frontiers of expression.
                            <br /><br />
                            From the dystopian futures of cyberpunk classics to the uncanny valleys of virtual companions, AI's portrayal in popular culture reflects humanity's hopes, fears, and dreams about the future of intelligent machines. As AI continues to advance and permeate all aspects of society, its depiction in popular culture will undoubtedly evolve, offering new insights and perspectives on the human-machine relationship and the ever-expanding horizons of technological innovation.
                        </p>
                    </>
                },
            ]
        },
        {
            id: 11,
            title: 'Resources',
            icon: <FaBook />,
            header: 
                <>
                    <section className="docs-intro">
                        <p>
                        For those interested in delving deeper into the world of Artificial Intelligence (AI), a wealth of resources and materials are available to explore the latest research, insights, and developments in the field. Whether you're a novice enthusiast, a seasoned researcher, or an industry professional, the following resources offer valuable opportunities for learning, discovery, and engagement:                        
                        </p>
                    </section>
                </>,
            subsections: 
            [
                {
                    subtitle: 'Books',
                    data: 
                    <>
                        <p>
                            <ul>
                                <li>
                                "Artificial Intelligence: A Modern Approach" by Stuart Russell and Peter Norvig

                                </li>
                                <li>
                                "Deep Learning" by Ian Goodfellow, Yoshua Bengio, and Aaron Courville

                                </li>
                                <li>
                                "Superintelligence: Paths, Dangers, Strategies" by Nick Bostrom

                                </li>
                                <li>
                                "Machine Learning Yearning" by Andrew Ng

                                </li>
                                <li>
                                "Human Compatible: Artificial Intelligence and the Problem of Control" by Stuart Russell

                                </li>
                                <li>
                                "The Master Algorithm: How the Quest for the Ultimate Learning Machine Will Remake Our World" by Pedro Domingos

                                </li>
                            </ul>
                        </p>
                    </>
                },
                {
                    subtitle: 'Journals and Research Papers',
                    data: 
                    <>
                        <p>
                            <ul>
                                <li>
                                "Journal of Artificial Intelligence Research (JAIR)"

                                </li>
                                <li>
                                "Neural Information Processing Systems (NeurIPS)"

                                </li>
                                <li>
                                "Machine Learning Journal"

                                </li>
                                <li>
                                "IEEE Transactions on Pattern Analysis and Machine Intelligence (TPAMI)"

                                </li>
                                <li>
                                "Nature Machine Intelligence"

                                </li>
                                <li>
                                "ArXiv: Artificial Intelligence"

                                </li>
                            </ul>
                        </p>
                    </>
                },
                {
                    subtitle: 'Online Courses and Tutorials',
                    data: 
                    <>
                        <p>
                            <ul>
                                <li>
                                Coursera: "AI For Everyone" by Andrew Ng
                                </li>
                                <li>
                                Udacity: "Machine Learning Engineer Nanodegree" by Kaggle and AWS
                                </li>
                                <li>
                                edX: "Artificial Intelligence (AI)" by Columbia University
                                </li>
                                <li>
                                Stanford Online: "CS229: Machine Learning" by Andrew Ng
                                </li>
                                <li>
                                Fast.ai: Practical Deep Learning for Coders course
                                </li>
                            </ul>
                        </p>
                    </>
                },
                {
                    subtitle: 'Organizations and Conferences',
                    data: 
                    <>
                        <p>
                            <ul>
                                <li>
                                Association for the Advancement of Artificial Intelligence (AAAI)                                </li>
                                <li>
                                International Conference on Machine Learning (ICML)                                </li>
                                <li>
                                Conference on Neural Information Processing Systems (NeurIPS)                                </li>
                                <li>
                                International Joint Conference on Artificial Intelligence (IJCAI)                                </li>
                                <li>
                                OpenAI                                </li>
                            </ul>
                            <br /><br />
                            These resources provide a comprehensive overview of AI research, education, and community engagement opportunities, enabling individuals to deepen their understanding, hone their skills, and stay up-to-date with the latest advancements in this rapidly evolving field. Whether through textbooks, academic journals, online courses, or participation in conferences and organizations, the pursuit of knowledge and innovation in AI knows no bounds.
                        </p>
                    </>
                },
            ]
        },
        {
            id: 12,
            title: 'Glossary of AI Terms',
            icon: <FaQuestion />,
            header: 
                <>
                    <section className="docs-intro">
                        <p>
                            Artificial Intelligence (AI) encompasses a vast array of concepts, techniques, and methodologies. Understanding the terminology is essential for navigating the landscape of AI research, applications, and discussions. Below are definitions of key terms and concepts related to AI:
                            <br /><br />
                            <ul>
                                <li>
                                    <strong>Artificial Intelligence (AI):</strong> The simulation of human intelligence in machines that are programmed to think, learn, and perform tasks traditionally requiring human intelligence.
                                </li>
                                <li>
                                    <strong>Machine Learning:</strong> A subset of AI that enables systems to learn from data and improve their performance without being explicitly programmed.
                                </li>
                                <li>
                                    <strong>Deep Learning:</strong> A type of machine learning that utilizes neural networks with multiple layers to extract high-level features from raw data.
                                </li>
                                <li>
                                    <strong>Neural Networks:</strong> Computational models inspired by the structure and function of the human brain, composed of interconnected nodes (neurons) organized into layers.
                                </li>
                                <li>
                                    <strong>Supervised Learning:</strong> A machine learning technique where the model is trained on labeled data, learning to map input data to corresponding output labels.
                                </li>
                                <li>
                                    <strong>Unsupervised Learning:</strong> A machine learning technique where the model is trained on unlabeled data, learning to identify patterns and structures within the data without explicit guidance.
                                </li>
                                <li>
                                    <strong>Reinforcement Learning:</strong> A machine learning technique where an agent learns to interact with an environment by taking actions to maximize a cumulative reward signal.
                                </li>
                                <li>
                                    <strong>Convolutional Neural Networks (CNNs):</strong> A type of neural network designed for processing structured grid-like data, such as images and videos, by applying convolutional operations.
                                </li>
                                <li>
                                    <strong>Recurrent Neural Networks (RNNs):</strong> A type of neural network designed for processing sequential data, such as time-series data or natural language sequences, by maintaining internal state and capturing temporal dependencies.
                                </li>
                                <li>
                                    <strong>Artificial General Intelligence (AGI):</strong> AI systems with human-level intelligence across a wide range of tasks, capable of understanding, learning, and reasoning in diverse domains.
                                </li>
                                <li>
                                    <strong>Bias in AI:</strong> Systematic errors or inaccuracies in AI systems due to the presence of biases in the data or design decisions, leading to unfair or discriminatory outcomes.
                                </li>
                                <li>
                                <strong>Data Privacy:</strong> The protection of personal data from unauthorized access, use, or disclosure, ensuring individuals' rights to control their own information.
                                </li>
                                <li>
                                    <strong>Autonomous Weapons:</strong> AI-powered weapons systems capable of making decisions and taking actions without human intervention, raising ethical and legal concerns about accountability and control.
                                </li>
                                <li>
                                    <strong>Human-AI Collaboration:</strong> The collaboration and interaction between humans and AI systems to leverage their complementary strengths and abilities in solving complex problems and achieving shared goals.
                                </li>
                            </ul>
                            <br /><br />
                            This glossary provides a foundational understanding of key terms and concepts in the field of AI, serving as a valuable reference for researchers, practitioners, and enthusiasts alike. As AI continues to evolve and shape the future of technology and society, staying informed and knowledgeable about its fundamental principles and terminology is essential for navigating the complexities and opportunities of this transformative field.
                                
                        </p>
                    </section>
                </>,
            subsections: 
            []
                
        },
    ]

    function createSubsection(subsectionData, sectionIndex, subIndex) {
        return (
            <section className="docs-section" id={"item-" + sectionIndex + "-" + Number(subIndex + 1)}>
				<h2 className="section-heading">{subsectionData.subtitle}</h2>
                {subsectionData.data}
			</section>
        )

    }

    function createSection(sectionData) {
        return (
                <div className="container">
                    <article className="docs-article" id={"section-" + sectionData.id}>
                        <header className="docs-header">
                            <h1 className="docs-heading">
                                {sectionData.title}
                            </h1>
                            {sectionData.header}
                        </header>

                        {sectionData.subsections.map((subsection, subIndex) => createSubsection(subsection, sectionData.id, subIndex))}

			        </article>
                </div>
        )
    }

    function createSidebarSubsections(sectionData) {
        // console.log(sectionData.subsections)
        return (
            sectionData.subsections.map((subsection, subsectionIndex) => {
                return(
                    <li className="nav-item">
                        <a className="nav-link scrollto" href={"#item-" + sectionData.id + "-" + Number(subsectionIndex + 1)}>
                            {subsection.subtitle}
                        </a>
                    </li>
                )
            })
        )
    }

    function createSidebarSection(sectionData) {
        return (
            <>
            <li className="nav-item section-title">
                <a className="nav-link scrollto" href={"#section-"+sectionData.id}>
                    <span className="theme-icon-holder me-2">
                        {sectionData.icon}
                    </span>
                    {sectionData.title}
                </a>
            </li>
            {createSidebarSubsections(sectionData)}
            </>
        )
    }

    function Sidebar(guideData) {
        let data = guideData.guideData;
        return (    
            <div id="docs-sidebar" className="docs-sidebar sidebar-visible">
                {/* <div className="top-search-box d-lg-none p-3">
                    <form className="search-form">
                        <input type="text" placeholder="Search the docs..." name="search" className="form-control search-input" />
                        <button type="submit" className="btn search-btn" value="Search"><i className="fas fa-search"></i></button>
                    </form>
                </div> */}
                <nav id="docs-nav" className="docs-nav navbar">
                    <ul className="section-items list-unstyled nav flex-column pb-3">
                        {data.map((sectionData) => createSidebarSection(sectionData))}
                    </ul>
                </nav>
	        </div>
        )
    }

    function Sections(guideData) {
        let data = guideData.guideData;
        return (
            data.map((sectionData) => createSection(sectionData))
        )
    }



    return (
        <>
            <NavbarTop />

            <main>
                <div className="w-100 container" id="guide">

                    <div className='d-none d-md-block'>
                    <Sidebar guideData={guideData} />
                    </div>
                    <div className='docs-content-row'>
                        <div className="docs-content-margin d-none d-md-block">
                        </div>

                        <div className="docs-content">
                            <Sections guideData={guideData} />
                        </div>
                    </div>
                    {/* <div className="container">
                        <h1 className="display-huge fw-bold pb-5" >
                            AI Resource Library
                        </h1>
                    </div> */}
                </div>
            </main>
            
            <FooterBottom />
        </>
    );
};