// Home.js
import React, { useEffect, useState, useContext } from 'react';
import NavbarTop from './NavbarTop';
import AdsenseAd from './AdsenseAd';
import FooterBottom from './FooterBottom';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import NewsContext from '../contexts/NewsContext';
import PromptsContext from '../contexts/PromptsContext';
import ModelsContext from '../contexts/ModelsContext';
import ReposContext from '../contexts/ReposContext';
import Spinner from 'react-bootstrap/Spinner';
import replaceHtml from '../utils/replaceHtml';
import { Link } from "react-router-dom";

const Home = () => {

    function MainContent() {

        const { loadingNews, news } = useContext(NewsContext);
        const { loadingPrompts, promptsList } = useContext(PromptsContext);
        const { loadingModels, modelsData } = useContext(ModelsContext);
        const { loadingRepos, reposList } = useContext(ReposContext);

        const [promptsListFiltered, setPromptsListFiltered] = useState([]);
        const [modelsListFiltered, setModelsListFiltered] = useState([]);

        useEffect(() => {
            // filter prompts based on rating
            if (promptsList.length === 0) {
                // no prompts, show no prompts
                setPromptsListFiltered([]);
                return;
            } else {
                // add the original index to each prompt (this is necessary as long as we don't have unique IDs for each prompt!! TO DO: FIX IN BACKEND!)
                promptsList.forEach((prompt, index) => {
                    prompt.originalIndex = index;
                })
                
                // randomize the list then pick 4 prompts
                promptsList.sort(() => Math.random() - 0.5);
                let promptsToDisplay = promptsList.slice(0, 4);
        
                // get prompts that have a rating and display the highest rated ones, overwrite the random prompts from above
                // if there are no prompts with a rating, just display the random prompts

                let promptsWithRating = promptsList.filter(prompt => prompt.rating);
                
                if (promptsWithRating.length > 0) {
                    promptsWithRating.sort((a, b) => b.rating - a.rating);
                    promptsToDisplay = [...promptsWithRating.slice(0, 4), ...promptsToDisplay]; // fill the list with random prompts from above
                    promptsToDisplay = promptsToDisplay.slice(0, 4);    // cut off at 4 prompts
                }
                // console.log(promptsToDisplay)
                setPromptsListFiltered(promptsToDisplay);
            }        
        }, [promptsList]);

        useEffect(() => {
            // filter models based on rank
            if (Object.keys(modelsData).length === 0) {
                // no models
                setModelsListFiltered([]);
                return;
            } else {
                // show the five models with highest finalRanking
                let modelsToDisplay = Object.values(modelsData).sort((a, b) => a.finalRanking - b.finalRanking).slice(0, 5);
                setModelsListFiltered(modelsToDisplay);
            }        
        }, [modelsData]);

        function DisplayTopNews() {
            return (
                <ListGroup className="mt-5 rounded-3 shadow">
                    {news.length > 0 ?
                        // show top 3 news articles
                        news.slice(0, 3).map((news, index) => {
                            if (news && news.title) {
                                if (news.title.length > 0 && news.summary.length > 0) {
                                    return (
                                        <ListGroupItem className="home-top-news" key={index}>
                                        <div className='row m-0'>
                                                <div className='col-2 pt-2 pb-2 ps-0 pe-2 align-self-center'>
                                            <Link to={'/news/' + index}>
                                                    <img src={news.image} alt={news.title} className="img-fluid img-ratio-16-9" />
                                            </Link>
                                                    </div>
                                                    <div className="col-10 p-2">
                                            <Link to={'/news/' + index}>
                                                        <h5>
                                                            {news.title.length > 85 ? news.title.substring(0, 85) + '...'
                                                            : news.title 
                                                            }
                                                        </h5>
                                                        {' ' + replaceHtml(news.summary).substring(0, 85) + '...'} 
                                            </Link>
                                                    </div>
                                                </div>
                                        </ListGroupItem>
                                    )
                                } else {
                                    return null;
                                }
                            } else {
                                return null;
                            }
                        })
                        : null
                    }
                </ListGroup>
            )
        }

        function DisplayTopPrompts() {
            return (
                <ListGroup className="mt-5 rounded-3 shadow">
                    {promptsListFiltered.length > 0 ?
                        promptsListFiltered.map((prompt, index) => {
                            if (prompt && prompt.title) {
                                if (prompt.title.length > 0 && prompt.prompt.length > 0) {
                                    return (
                                        <ListGroupItem className="home-top-prompts" key={index}>
                                            <Link to={'/prompts/' + prompt.originalIndex}>
                                                <div className="pt-2 pb-2">
                                                    <h5>
                                                        {prompt.title}
                                                    </h5>
                                                    {' ' + prompt.prompt.substring(0, 85) + '...'} 
                                                </div>
                                            </Link>
                                        </ListGroupItem>
                                    )
                                } else {
                                    return null;
                                }
                            } else {
                                return null;
                            }
                        })
                        : null
                    }
                </ListGroup>
            )
        }

        function DisplayTopRepos() {
            return (
                <ListGroup className="mt-5 rounded-3 shadow">
                    {reposList.length > 0 ?
                        reposList.slice(0, 4).map((repo, index) => {
                            if (repo && repo.repo_name) {
                                if (repo.repo_name.length > 0 && repo.repo_description_gpt.length > 0) {
                                    return (
                                        <ListGroupItem className="home-top-prompts" key={index}>
                                            <Link to={'/repos/' + index}>
                                                <div className="pt-2 pb-2">
                                                    <h5>
                                                        {repo.repo_name}
                                                    </h5>
                                                    {' ' + repo.repo_description_gpt.substring(0, 85) + '...'} 
                                                </div>
                                            </Link>
                                        </ListGroupItem>
                                    )
                                } else {
                                    return null;
                                }
                            } else {
                                return null;
                            }
                        })
                        : null
                    }
                </ListGroup>
            )
        }

        function DisplayTopModels() {
            return (
                <ListGroup className="mt-5 rounded-3 shadow">
                    {modelsListFiltered.length > 0 ?
                        modelsListFiltered.map((model, index) => {
                            if (model) {
                                if (model.model.length > 0) {
                                    return (
                                        <ListGroupItem className="home-top-models">
                                            <Link to='/models'>
                                                <div className="pt-2 pb-2">
                                                    <h5>{(index+1) + '. ' + model.model}</h5>
                                                    {model.organization}
                                                </div>
                                            </Link>
                                        </ListGroupItem>
                                    )
                                } else {
                                    return null;
                                }
                            } else {
                                return null;
                            }
                        })
                        : null
                    }
                </ListGroup>
            )
        }
        return(
            <main>
                <div className="w-100" id="news">
                    <div className="container position-relative">
                        <div className="row">
                            <div className="col-lg-6 py-vh-4 position-relative" data-aos="fade-right"> {/* data-aos="fade-right" */}
                                <h1 className="display-1 fw-bold mt-5">Latest AI News</h1>
                                <p className="lead">
                                    Get the latest developments on artificial intelligence!
                                </p>
                                <Link to="/news" className="btn btn-primary btn-xl shadow me-3 rounded-5 my-5">
                                    <strong>Browse AI news</strong>
                                </Link>
                            </div>

                            <div className="col-lg-6 py-vh-4 position-relative" data-aos="fade-left"> {/* data-aos="fade-left" */}
                                {loadingNews ? (
                                    <div className="py-vh-3 pb-0 h-100 d-flex align-items-center justify-content-center">
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                ) : (
                                    <DisplayTopNews />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-100 bg-gray-100" id="models">
                    <div className="container position-relative">
                        {/* <div className="col-12 col-lg-8 mt-0 h-100 position-absolute top-0 end-0 bg-cover" data-aos="fade-left" style={{backgroundImage: 'url(img/webp/prompt2.webp)'}}>
                        </div> */}
                        <div className="row d-flex flex-column flex-lg-row">
                            <div className="col-lg-6 py-vh-4 position-relative order-2 order-lg-1" data-aos="fade-right"> {/* data-aos="fade-left" */}
                                {loadingModels ? (
                                    <div className="py-vh-3 pb-0 h-100 d-flex align-items-center justify-content-center">
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                ) : (
                                    <DisplayTopModels />
                                )}
                            </div>

                            <div className="col-lg-6 py-vh-4 position-relative order-1 order-lg-2" data-aos="fade-left"> {/* data-aos="fade-right" */}
                                <h1 className="display-1 fw-bold mt-5">AI Model Leaderboard</h1>
                                <p className="lead">
                                    Compare the best GenAI models for commercial or personal use. ✨
                                </p>
                                <Link to="/models" className="btn btn-primary btn-xl shadow me-3 rounded-5 my-5">
                                    <strong>Compare now</strong>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="w-100" id="repos">
                    <div className="container position-relative">
                        {/* <div className="col-12 col-lg-8 mt-0 h-100 position-absolute top-0 start-0 bg-cover" data-aos="fade-right" style={{backgroundImage: 'url(img/webp/models2.webp)'}}>
                        </div> */}
                        <div className="row d-flex justify-content-end">
                            

                            <div className="col-lg-6 py-vh-4 position-relative" data-aos="fade-right"> {/* data-aos="fade-left" */}
                                <h1 className="display-1 fw-bold mt-5">Trending AI Repos</h1>
                                <p className="lead">
                                    See which repositories are getting hype! 🔥
                                </p>
                                <Link to="/repos" className="btn btn-primary btn-xl shadow me-3 rounded-5 my-5">
                                    <strong>Browse hot repos</strong>
                                </Link>
                            </div>

                            <div className="col-lg-6 py-vh-4 position-relative" data-aos="fade-left">  {/* data-aos="fade-right" */}
                                {loadingRepos ? (
                                    <div className="py-vh-3 pb-0 h-100 d-flex align-items-center justify-content-center">
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                ) : (
                                    <DisplayTopRepos />
                                )}
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="w-100 bg-gray-100" id="prompts">
                    <div className="container position-relative">
                        {/* <div className="col-12 col-lg-8 mt-0 h-100 position-absolute top-0 end-0 bg-cover" data-aos="fade-left" style={{backgroundImage: 'url(img/webp/prompt2.webp)'}}>
                        </div> */}
                        <div className="row d-flex flex-column flex-lg-row">
                            <div className="col-lg-6 py-vh-4 position-relative order-2 order-lg-1" data-aos="fade-right"> {/* data-aos="fade-left" */}
                                {loadingPrompts ? (
                                    <div className="py-vh-3 pb-0 h-100 d-flex align-items-center justify-content-center">
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                ) : (
                                    <DisplayTopPrompts />
                                )}
                            </div>

                            <div className="col-lg-6 py-vh-4 position-relative order-1 order-lg-2" data-aos="fade-left"> {/* data-aos="fade-right" */}
                                <h1 className="display-1 fw-bold mt-5">GenAI Prompt Ideas</h1>
                                <p className="lead">
                                    Find the most creative prompts for any task! 🤓
                                </p>
                                <Link to="/prompts" className="btn btn-primary btn-xl shadow me-3 rounded-5 my-5">
                                    <strong>Browse top prompts</strong>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className="py-vh-5 w-100 overflow-hidden" id="guide">
                    <div className="container">
                        <div className="row d-flex justify-content-end">
                            <div className="col-lg-8" data-aos="fade-down">
                                <h2 className="display-6">
                                    GPTs? LLMs? Come again?
                                </h2>
                                <p className="lead">Don't worry, we got you covered. Learn all about Artificial Intelligence in our resource library!</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-down">
                                <Link to="/guide" className="btn btn-primary btn-xl shadow me-3 rounded-5 my-5" ><strong>Check it out</strong></Link>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div className="container bg-gray-100 py-vh-3 " data-aos="fade" data-aos-delay="200" id="newsletter">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-8 text-center">
                            <h3 className="fs-2 fw-light">Stay in touch with our <span className="fw-bold">
                                monthly and free</span> newsletter!
                            </h3>
                            <br></br>
                        </div>
                        <div className="col-12 col-lg-8 text-center">
                            <div className="row">
                                <div className="grouped-inputs bg-light p-3 rounded-4">
                                    <div className="row">
                                        <div className="col">
                                            <form className="form-floating">
                                            <input type="email" className="form-control p-3 rounded-5" id="floatingInput" placeholder="name@example.com"  />
                                            <label htmlFor="floatingInput">Email address</label>
                                            </form>
                                        </div>
                                        <div className="col-auto">
                                            <Link to="/#" className="btn btn-primary py-3 px-5 rounded-5">
                                                <strong>Subscribe</strong>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                
            </main>
        );
    };

    return (
        <>
            <NavbarTop />
            <MainContent />

            {/* <AdsenseAd dataAdSlot='X7XXXXXX5X' /> */}
            <FooterBottom />
        </>
    );
};

export default Home;

// Platzieren Sie einen Anzeigenblock im Format 320 x 100 „above the fold“ (ohne Scrollen sichtbar)
// Platzieren Sie einen für Mobilgeräte optimierten 300x250-Anzeigenblock „below the fold“ (mit Scrollen sichtbar)

// TO DO
// 1. get user screen size
// 2. calculate where the fold is (approx) = display area of browser (height in px)
// 3. if mobile user: place ads optimized for mobile (1 above, 1 below fold)
// 4. if pc user: place ads optimized for PC (other dimensions, bigger, ...)
