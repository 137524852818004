// ReposDetail.js
import React from 'react';
import { Modal } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from "react-router-dom";
import { IoStar } from "react-icons/io5";
import { FaCodeFork } from "react-icons/fa6";
import { IoCode } from "react-icons/io5";
import { replacePercent23 } from '../utils/parseHtml';

export default function ReposDetail({ show, onHide, repoItem, onPrev, onNext }) {

    const TooltipItem = ({ id, children, title }) => (
        <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>} placement="right">
          {children}
        </OverlayTrigger>
      );

    return (
        <Modal show={show} onHide={onHide} dialogClassName="repos-modal">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="row modal-repos-container">
                    <div className="modal-repos d-flex">
                        
                        
                        <div className='float-start repo-info d-flex mb-4'>
                            <div className='d-flex float-start'>
                                <h2><strong>{repoItem.repo_name}</strong></h2>
                            </div>
                            <div className="d-flex modal-text repos-card-text float-start mt-2 mb-2">
                                {
                                    repoItem.repo_description_gpt ? 
                                    repoItem.repo_description_gpt
                                    : null
                                }
                            </div>

                            <Link to={repoItem.repo_link} replace={true}>View on GitHub</Link>


                        </div>
                        
                        <div className='float-end repo-stats d-flex'>
                            
                            <TooltipItem id={'stars_today'} title='Stars Today'>
                                <div className='repo-stats-item'>
                                    <div className='d-flex float-start repo-stars-inner-top'>
                                        <div className='repo-stars-icon'>
                                            <IoStar size='24px' color='#ffc107'/>
                                        </div>
                                        <div className='repo-stars-text'>
                                            {repoItem.stars_today}
                                        </div>
                                    </div>
                                </div>
                            </TooltipItem>
                            <TooltipItem id={'stars_total'} title='Stars Total'>
                                <div className='repo-stats-item'>
                                    <div className='d-flex float-start repo-stars-inner-top'>
                                        <div className='repo-stars-icon'>
                                            <IoStar size='24px' color='#fd7e14'/>
                                        </div>
                                        <div className='repo-stars-text'>
                                            {repoItem.stars}
                                        </div>
                                    </div>
                                </div>
                            </TooltipItem>
                            <TooltipItem id={'forks'} title='Forks'>
                                <div className='repo-stats-item'>
                                    <div className='d-flex float-start repo-stars-inner-top'>
                                        <div className='repo-stars-icon'>
                                            <FaCodeFork size='24px' color='#495057'/>
                                        </div>
                                        <div className='repo-stars-text'>
                                            {repoItem.forks}
                                        </div>
                                    </div>
                                </div>
                            </TooltipItem>
                            <TooltipItem id={'lang'} title='Programming Language'>
                                <div className='repo-stats-item'>
                                    <div className='d-flex float-start repo-stars-inner-top'>
                                        <div className='repo-stars-icon'>
                                            <IoCode size='24px' color='#495057'/>
                                        </div>
                                        <div className='repo-stars-text'>
                                            {replacePercent23(repoItem.language)}
                                        </div>
                                    </div>
                                </div>
                            </TooltipItem>
                        </div>


                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
