// ModelsPage.js
import React, { useEffect, useState, useContext } from 'react';
import NavbarTop from './NavbarTop';
import Spinner from 'react-bootstrap/Spinner';
import FooterBottom from './FooterBottom';
import ModelsContext from '../contexts/ModelsContext';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, dateFilter, numberFilter } from 'react-bootstrap-table2-filter';
import { Link } from "react-router-dom";

export default function ModelsPage() {

    function DisplayModels() {
        const { loadingModels, modelsData, organizationsData } = useContext(ModelsContext);         
        
        // table data is empty until models loaded
        const [tableData, setTableData] = useState([]);

        const parseDateString = (dateString) => {
            // check if datestring is an empty field (if we have no cutoff date)
            if (!dateString || dateString == null || dateString === '') {
                const [month, year] = [ "01", "1900"]
                return { month, year };
            }
            const [month, year] = dateString.split(/[/\.]/).map(Number); // regex split for / or . sign in date string
            // console.log(dateString, month, year)
            return { month, year };
        };

        useEffect(() => {
            function buildData(data) {
                // set onclick to stop click propagation of Link to Table Row (otherwise we expand a row on clicking a link in the row)
                const onClick = e => e.stopPropagation();
                // set unique key
                let uniqueKey = 0;
                // set empty array
                let tableData = [];
                for (let i = 0; i < data.length; i++) {
                    // console.log(data[i])
                    let model = data[i];
                    
                    // set model name
                    let modelName = model.model;
                    let modelNameString = model.model;
                    if (model.link) {
                        modelName = <span onClick={ onClick }><Link to={model.link}>{model.model}</Link></span>;
                    }

                    // set license
                    let modelLicense = null;
                    let modelLicenseString = null;
                    if (model.license) {
                        modelLicense = model.license;
                        modelLicenseString = model.license;
                    }
                    if (model.licenseUrl) {
                        modelLicense = <span onClick={ onClick }><Link to={model.licenseUrl}>{model.license}</Link></span>;
                    }

                    // round the rating
                    let modelRating = null;
                    if (model.rating) {
                        modelRating = round(model.rating, 0)
                    }

                    // format knowledge cutoff date
                    let modelKnowledgeCutoff = null;
                    if (model.knowledgeCutoffDate) {
                        // check if date is unknown, -, none or null
                        console.log(typeof(model.knowledgeCutoffDate))
                        if (model.knowledgeCutoffDate === '-' || model.knowledgeCutoffDate === 'Unknown' || model.knowledgeCutoffDate === 'unknown') {
                            console.log('1', model.model, model.knowledgeCutoffDate)
                            modelKnowledgeCutoff = null;
                        } else if (model.knowledgeCutoffDate === ('Online' || 'online' || 'Today' || 'today')) {
                            console.log('2', model.model, model.knowledgeCutoffDate)
                            // take current date, format to MM/YYYY
                            modelKnowledgeCutoff = new Date().toLocaleString('default', { month: '2-digit', year: 'numeric' });
                        } else {
                            console.log('3', model.model, model.knowledgeCutoffDate, model.knowledgeCutoffDate === ('unknown' || 'Unknown' || '-' || 'none' || 'None' || 'null'))
                            // format given date (date format given is MM/YYYY)
                            const dateString = model.knowledgeCutoffDate;
                            const [year, month] = dateString.split('/');
                            const formattedDate = new Date(year, month - 1); // Month is zero-based
                            modelKnowledgeCutoff = formattedDate.toLocaleDateString('default', { month: '2-digit', year: 'numeric' });
                        }
                    }

                    // check commercial usage rights
                    let modelCommercialUse = null;
                    let modelCommercialUseString = null;
                    if (model.freeForCommercialUse) {
                        if (model.freeForCommercialUse === ('yes' || 'Yes')) {
                            modelCommercialUse = 'Free';
                            modelCommercialUseString = 'Free';
                        } else {
                            let organizationPricingUrl = null;
                            if (model.organization) {
                                // check if organization has pricing page, use breakable for loop
                                for (let organization of organizationsData) {
                                    // organization name can be array or string
                                    if (Array.isArray(organization.name)) {
                                        // if name is array, check each entry (diff. spellings or name variants)
                                        // user find() method to find matching entry in array, returns undefined if not found
                                        if (organization.name.find(name => name === model.organization)) {
                                            organizationPricingUrl = organization.pricingUrl;
                                            break;
                                        } 
                                    }
                                    else if (organization.name === model.organization) {
                                        organizationPricingUrl = organization.pricingUrl;
                                        break;
                                    }
                                }
                            }
                            // usually we should have found a pricingUrl now
                            if (organizationPricingUrl) {
                                modelCommercialUse = <span onClick={ onClick }><Link to={organizationPricingUrl}>API Pricing</Link></span>;
                                modelCommercialUseString = 'API Pricing';
                            } else {
                                // no pricing url found (or no organization found), model is either not for commerical use or with restrictions, see original data
                                if (model.freeForCommercialUse === ('no' || 'No')) {
                                    modelCommercialUse = 'No';
                                    modelCommercialUseString = 'No';
                                } else {
                                    modelCommercialUse = 'Restricted';
                                    modelCommercialUseString = 'Restricted';
                                }
                            }

                        }
                    }
                    tableData.push({
                        id: uniqueKey,
                        modelName: modelName,
                        modelNameString: modelNameString,
                        description: model.description ? model.description : null,
                        finalRanking: model.finalRanking ? model.finalRanking : null,
                        commercialUse: modelCommercialUse,
                        commercialUseString: modelCommercialUseString,
                        knowledgeCutoff: modelKnowledgeCutoff, 
                        license: modelLicense,
                        licenseString: modelLicenseString,
                        organization: model.organization ? model.organization : null,
                        rating: modelRating,
                    });
                    uniqueKey++;
                }
                return tableData;
            }

            if (modelsData) setTableData(buildData(modelsData));
        }, [modelsData, organizationsData]);

        // customize filters
        const customNumberFilter = numberFilter({
            delay: 400,  // how long will trigger filtering after user typing, default is 500 ms
            className: 'filter', // custom classname on input
        })
        const customDateFilter = dateFilter({
            delay: 400,  // how long will trigger filtering after user typing, default is 500 ms
            className: 'filter', // custom classname on input
        })
        const customTextFilter = textFilter({
            delay: 400,  // how long will trigger filtering after user typing, default is 500 ms
            className: 'filter', // custom classname on input
        })

        // define table cols
        const tableColumns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            hidden: true
        }, { 
            dataField: 'finalRanking',
            text: 'Rank',
            sort: true,
            headerFormatter: filterFormatter,
            headerStyle: () => {
                return { minWidth: '100px' };
            },
        }, {
            dataField: 'modelName',
            text: 'Model',
            sort: true,
            filter: customTextFilter,
            filterValue: (cell, row) => {   // this makes sure we filter for name and not <Link> element
                return `${row.modelNameString}`
            },
            headerFormatter: filterFormatter,
            headerStyle: () => {
                return { minWidth: '200px' };
            }
        }, {
            dataField: 'commercialUse',
            text: 'Commercial Use',
            sort: true,
            filter: customTextFilter,
            filterValue: (cell, row) => {   // this makes sure we filter for name and not <Link> element
                return `${row.commercialUseString}`
            },
            headerFormatter: filterFormatter,
            headerStyle: () => {
                return { minWidth: '200px' };
            }

        }, {
            dataField: 'license',
            text: 'License',
            sort: true,
            filter: customTextFilter,
            filterValue: (cell, row) => {   // this makes sure we filter for name and not <Link> element
                return `${row.licenseString}`
            },
            headerFormatter: filterFormatter,
            headerStyle: () => {
                return { minWidth: '200px' };
            }
        }, {
            dataField: 'knowledgeCutoff',
            text: 'Knowledge Cutoff',
            sort: true,
            // filter: customDateFilter,
            // filterValue: (cell, row) => {   // this makes sure we filter for full date
            //     return new Date(cell);
            // },
            // custom sort func for dates
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                // Parse date strings into components
                const dateA = parseDateString(a);
                const dateB = parseDateString(b);
                  
                // Handle cases where dates might be empty
                if (!dateA && !dateB) return 0; // Both are empty
                if (!dateA) return 1; // DateA is empty, put it at the bottom
                if (!dateB) return -1; // DateB is empty, put it at the bottom

                // Compare the dates
                if (order === 'asc') {
                    return dateA.year !== dateB.year ? dateA.year - dateB.year : dateA.month - dateB.month;
                }
                return dateA.year !== dateB.year ? dateB.year - dateA.year : dateB.month - dateA.month;
            },
            // headerFormatter: filterFormatter,
        }, {
            dataField: 'rating',
            text: 'Rating',
            sort: true,
            headerFormatter: filterFormatter,
            headerStyle: () => {
                return { minWidth: '100px' };
            },
        }, {
            dataField: 'organization',
            text: 'Organization',
            sort: true,
            filter: customTextFilter,
            headerFormatter: filterFormatter,
            headerStyle: () => {
                return { minWidth: '160px' };
            }
        }];

        // how to sort the table 
        const defaultSorted = [{
            dataField: 'finalRanking',
            order: 'asc'
        }];
        
        // format table headers / filters
        function filterFormatter(column, colIndex, { sortElement, filterElement }) {
            return (
                <div style={ { display: 'flex', flexDirection: 'column' } }>
                    { filterElement }
                    <div style={ { display: 'flex', flexDirection: 'row' } }>
                        { column.text }
                        { sortElement }
                    </div>
              </div>
            );
        }

        function round(value, precision) {
            var multiplier = Math.pow(10, precision || 0);
            return Math.round(value * multiplier) / multiplier;
        }
        

        // what to show on expanding a row (on clicking it)
        const expandRow = {
            renderer: (row, rowIndex) => (
                row.description ?
                <div >
                    <p className='model-table-description'>{row.description}</p>

                </div>
                : null
            ),
            showExpandColumn: true,
            expandColumnPosition: 'right'
          };

        return(
            <>
            {(loadingModels && tableData.length > 0) ? (
                <div className="py-vh-3 pb-0 h-100 d-flex align-items-center justify-content-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <BootstrapTable 
                keyField='id' 
                data={ tableData } 
                columns={ tableColumns } 
                filter={ filterFactory() }
                defaultSorted={ defaultSorted }
                striped={true} 
                bordered={false} 
                hover={true} 
                classes="w-auto"
                wrapperClasses="table-responsive-sm"
                expandRow={ expandRow }
                />
            )}
            </>
        );
    }
    
    return (
        <>
            <NavbarTop />

            <main>
                <div className="py-vh-4 w-auto" id="models">
                    <div className="container">
                        <h1 className="display-huge fw-bold pb-5" >
                            GenAI Model Leaderboard
                        </h1>


                        {/* models table */}
                        <DisplayModels />
                        <div className="mt-5"></div>

                        {/* disclaimer / info */}
                        <h5>Rank</h5>
                        <p className="">
                            The model's ranking, defined as one plus the number of models that are statistically better than the target model. Model A is statistically better than Model B when A's lower-bound score is greater than B's upper-bound score (with 95% confidence). 
                        </p>
                        <h5>Rating</h5>
                        <p className="">
                            Over 1,000,000 human pairwise comparisons were used to rank GenAI models with the <a href="https://en.wikipedia.org/wiki/Bradley%E2%80%93Terry_model">Bradley-Terry model</a> and display ratings on an <a href="https://en.wikipedia.org/wiki/Elo_rating_system">Elo scale</a>. 
                        </p>
                        <h5>Sources</h5>
                        <p className="">
                            <a href="https://chat.lmsys.org/">LMSYS</a>, <a href="https://arxiv.org/abs/2403.04132">Scientific Paper</a>
                        </p>

                    </div>
                </div>
            </main>

            <FooterBottom />
        </>
    );
};