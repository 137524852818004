// CookiesPage.js
import React from 'react';
import NavbarTop from './NavbarTop';
import FooterBottom from './FooterBottom';

export default function CookiesPage() {

    function CookiesPolicy() {

        return(
            <main>
                <div className="py-vh-4 w-100 overflow-hidden" id="impressum">
                    <div className="container">
                        <div className="row d-flex align-items-center position-relative justify-content-center px-0 g-5">
                            <h1 className="display-huge fw-bold">Cookies Policy</h1>

                           
                        </div>
                    </div>
                </div>
            </main>
        );
    };

    return (
        <>
            <NavbarTop />
            <CookiesPolicy />
            <FooterBottom />
        </>
    );
};