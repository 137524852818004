import React, { useCallback, useState, useEffect, useContext } from 'react'
import { ReactTags } from 'react-tag-autocomplete'
import NavbarTop from './NavbarTop';
import AdsenseAd from './AdsenseAd';
import FooterBottom from './FooterBottom';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ReposContext from '../contexts/ReposContext';
import ReposDetail from './ReposDetail';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { IoStar } from "react-icons/io5";
import { FaCodeFork } from "react-icons/fa6";
import { IoCode } from "react-icons/io5";
import { replacePercent23 } from '../utils/parseHtml';

export default function ReposPage() {
    const { id } = useParams();
    // console.log(id)

    const [selected, setSelected] = useState([])
    const [tagSuggestions, setTagSuggestions] = useState([])
    const [reposListFiltered, setReposListFiltered] = useState([]);
    const { loadingRepos, reposList } = useContext(ReposContext);

    const [showModal, setShowModal] = useState(false);
    const [detailRepoIndex, setDetailRepoIndex] = useState(null);

    useEffect(() => {
        if (id && reposList.length > 0) {
            let routedRepoId = parseInt(id);
            handleRepoClick(routedRepoId); // simulate a click on the news that was in the routed params
        }
    }, [id, reposList]);

    useEffect(() => {
        // console.log(reposList)
        // update suggestions with keywords from all repos
        if (reposList.length !== 0) {
            let reposTags = [];
            reposList.forEach((repo) => {
                if (repo.keywords) {
                    // only add keywords that are not in the list already
                    repo.keywords.forEach((keyword) => {
                        if (!reposTags.includes(keyword)) reposTags = [...reposTags, keyword];
                    })
                }
                if (repo.language) {

                    let programmingLang = replacePercent23(repo.language); // replace so c%23 becomes c#
                    // also add the programming lang as a tag
                    if (!reposTags.includes(programmingLang)) reposTags = [...reposTags, programmingLang];
                }
            })
            // suggestions need a specific format: [{ value: 1, label: 'tag1' }, { value: 2, label: 'tag2' }, ... ]
            // so we need to create a new array with the correct format
            if (reposTags.length > 0) {
                reposTags.forEach((tag, index) => {
                    reposTags[index] = { value: index + 1, label: tag };
                })
                setTagSuggestions(reposTags);
            }
        }
    }, [reposList]);

    // filter repos based on selected tags
    useEffect(() => {
        if (selected.length === 0) {
            // no tags selected, show all repos
            setReposListFiltered(reposList);
            return;
        } 
        else if (reposList.length === 0) {
            // no repos, show no repos
            setReposListFiltered([]);
            return;
        }
        else {
            // show all repos that include any of the tags in list (if user chooses multiple tags they are searched with "OR" logic)
            let selectedTags = selected.map(item => item.label);
            setReposListFiltered(reposList.filter(repo => {
                if (repo.keywords && repo.language) {
                    return repo.keywords.some(tag => selectedTags.includes(tag)) || selectedTags.includes(repo.language);
                } else if (repo.keywords) {
                    return repo.keywords.some(tag => selectedTags.includes(tag));
                } else if (repo.language) {
                    return selectedTags.includes(repo.language);
                } else {
                    return false;
                }
            }));
            // console.log(selectedTags)
        } 
    }   
    , [reposList, selected]);

    const TooltipItem = ({ id, children, title }) => (
        <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>} placement="right">
          {children}
        </OverlayTrigger>
      );

    const onAdd = useCallback(
        (newTag) => {
            setSelected([...selected, newTag])
        },
        [selected]
    )

    const onDelete = useCallback(
        (tagIndex) => {
            setSelected(selected.filter((_, i) => i !== tagIndex))
        },
        [selected]
    )

    const handleRepoClick = (index) => {
        setDetailRepoIndex(index);
        setShowModal(true);
    };
    
    const handlePrevRepo = () => {
        const prevIndex = detailRepoIndex - 1;
        setDetailRepoIndex(prevIndex < 0 ? reposList.length - 1 : prevIndex);  // after the newest news item we go back to the last one
    };
    
    const handleNextRepo = () => {
        const nextIndex = detailRepoIndex + 1;
        setDetailRepoIndex(nextIndex >= reposList.length ? 0 : nextIndex);
    };
    
    const handleCloseModal = () => {
        setDetailRepoIndex(null);
        setShowModal(false);
    };

    function DisplayRepos() {
        if (loadingRepos) {
            return (
                <div className="py-vh-3 pb-0 h-100 d-flex align-items-center justify-content-center" id='spinner' key='spinner'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )
        } else {
            return (
                reposListFiltered.map((repo, index) => (   
                    <div className="col-12 repo-card d-flex" onClick={() => handleRepoClick(index)} id={index} key={index}>
                        <div className='float-start repo-info d-flex'>
                            <div className='d-flex float-start'>
                                <h2><strong>{repo.repo_name}</strong></h2>
                            </div>
                            <div className="d-flex modal-text repos-card-text float-start mt-2">
                                {
                                    repo.repo_description_short ? 
                                    (repo.repo_description_short.length > 150 ? 
                                        repo.repo_description_short.substring(0, 150) + '...'
                                        : repo.repo_description_short) 
                                    : repo.repo_description_gpt ?
                                        (repo.repo_description_gpt.length > 150 ? 
                                            repo.repo_description_gpt.substring(0, 150) + '...'
                                            : repo.repo_description_gpt) 
                                        : null
                                }
                            </div>

                            <div className='d-flex repos-card-keywords float-end mt-3'>
                                {
                                    (repo.keywords ? 
                                        repo.keywords.map((tag) => <div className="float-start repo-keyword" key={tag}>{tag}</div>)
                                    : null)
                                }
                                {/* <Link to={repo.repo_link} replace={true} className="d-flex float-end">View on GitHub</Link> */}
                            </div>
                        </div>
                        
                        <div className='float-end repo-stats d-flex'>
                            
                            <TooltipItem id={'stars_today' + index} title='Stars Today'>
                                <div className='repo-stats-item'>
                                    <div className='d-flex float-start repo-stars-inner-top'>
                                        <div className='repo-stars-icon'>
                                            <IoStar size='24px' color='#ffc107'/>
                                        </div>
                                        <div className='repo-stars-text'>
                                            {repo.stars_today}
                                        </div>
                                    </div>
                                </div>
                            </TooltipItem>
                            <TooltipItem id={'stars_total' + index} title='Stars Total'>
                                <div className='repo-stats-item'>
                                    <div className='d-flex float-start repo-stars-inner-top'>
                                        <div className='repo-stars-icon'>
                                            <IoStar size='24px' color='#fd7e14'/>
                                        </div>
                                        <div className='repo-stars-text'>
                                            {repo.stars}
                                        </div>
                                    </div>
                                </div>
                            </TooltipItem>
                            <TooltipItem id={'forks' + index} title='Forks'>
                                <div className='repo-stats-item'>
                                    <div className='d-flex float-start repo-stars-inner-top'>
                                        <div className='repo-stars-icon'>
                                            <FaCodeFork size='24px' color='#495057'/>
                                        </div>
                                        <div className='repo-stars-text'>
                                            {repo.forks}
                                        </div>
                                    </div>
                                </div>
                            </TooltipItem>
                            <TooltipItem id={'lang' + index} title='Programming Language'>
                                <div className='repo-stats-item'>
                                    <div className='d-flex float-start repo-stars-inner-top'>
                                        <div className='repo-stars-icon'>
                                            <IoCode size='24px' color='#495057'/>
                                        </div>
                                        <div className='repo-stars-text'>
                                            {replacePercent23(repo.language)}
                                        </div>
                                    </div>
                                </div>
                            </TooltipItem>
                        </div>

                        
                    </div>
                ))
            )
        }
    }

    return (
        <>
            <NavbarTop />

            {/* Detailed repo view modal */}
            {detailRepoIndex !== null && (
                <ReposDetail
                show={showModal}
                onHide={handleCloseModal}
                repoItem={reposList[detailRepoIndex]}
                onPrev={handlePrevRepo}
                onNext={handleNextRepo}
                />
            )}

            <main>
                <div className="py-vh-4 w-100" id="repos">
                    <div className="container">
                        <h1 className="display-huge fw-bold pb-5" >
                            Trending AI Repos
                        </h1>
                        <div className="row">
                            <div className="col-12 col-lg-10 col-xl-8">
                                <ReactTags
                                labelText="Filter tags"
                                placeholderText="Filter tags"
                                selected={selected}
                                suggestions={tagSuggestions}
                                onAdd={onAdd}
                                onDelete={onDelete}
                                noOptionsText="No matching tags"
                                />
                            </div>
                        </div>
                        <div className="w-100 mb-5" id="reposlist">
                            <div className="row">
                                <div className="col-12 col-lg-10 col-xl-8 pb-5">
                                    <DisplayRepos />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <AdsenseAd dataAdSlot='X7XXXXXX5X' />
            <FooterBottom />
        </>
    );
};