import React, { useCallback, useState, useEffect, useContext } from 'react'
import { ReactTags } from 'react-tag-autocomplete'
import NavbarTop from './NavbarTop';
import AdsenseAd from './AdsenseAd';
import FooterBottom from './FooterBottom';
import Spinner from 'react-bootstrap/Spinner';
import PromptsContext from '../contexts/PromptsContext';
import PromptsDetail from './PromptsDetail';
import { IoCheckmarkCircle , IoCopy } from "react-icons/io5";
import { useParams } from 'react-router-dom';

export default function PromptsPage() {
    const { id } = useParams();
    // console.log(id)

    const [selected, setSelected] = useState([])
    const [tagSuggestions, setTagSuggestions] = useState([])
    const [promptsListFiltered, setPromptsListFiltered] = useState([]);
    const { loadingPrompts, promptsList } = useContext(PromptsContext);
    const [copiedIndex, setCopiedIndex] = useState(null);

    const [showModal, setShowModal] = useState(false);
    const [detailPromptIndex, setDetailPromptIndex] = useState(null);

    useEffect(() => {
        if (id && promptsList.length > 0) {
            let routedPromptId = parseInt(id);
            handlePromptClick(routedPromptId); // simulate a click on the news that was in the routed params
        }
    }, [id, promptsList]);

    useEffect(() => {
        // update suggestions with keywords from all prompts
        if (promptsList.length !== 0) {
            let promptsTags = [];
            promptsList.forEach((prompt) => {
                if (prompt.keywords) {
                    // only add keywords that are not in the list already
                    prompt.keywords.forEach((keyword) => {
                        if (!promptsTags.includes(keyword))
                            promptsTags = [...promptsTags, keyword];
                    })
                }
            })
            // suggestions need a specific format: [{ value: 1, label: 'tag1' }, { value: 2, label: 'tag2' }, ... ]
            // so we need to create a new array with the correct format
            if (promptsTags.length > 0) {
                promptsTags.forEach((tag, index) => {
                    promptsTags[index] = { value: index + 1, label: tag };
                })
                setTagSuggestions(promptsTags);
            }
        }
    }, [promptsList]);

    // filter prompts based on selected tags
    useEffect(() => {
        if (selected.length === 0) {
            // no tags selected, show all prompts
            setPromptsListFiltered(promptsList);
            return;
        } 
        else if (promptsList.length === 0) {
            // no prompts, show no prompts
            setPromptsListFiltered([]);
            return;
        }
        else {
            // show all prompts that include any of the tags in list (if user chooses multiple tags they are searched with "OR" logic)
            let selectedTags = selected.map(item => item.label);
            setPromptsListFiltered(promptsList.filter(prompt => {
                if (prompt.keywords) {
                    return prompt.keywords.some(tag => selectedTags.includes(tag))
                }
                else return false;
            }));
            // console.log(selectedTags)
        } 
    }   
    , [promptsList, selected]);


    const copyToClipboard = async (promptText) => {
        try {
            await navigator.clipboard.writeText(promptText);
            setCopiedIndex(promptText);
            setTimeout(() => {
                setCopiedIndex(null);
            }, 2000); // Hide the checkmark after 2 seconds
        } catch (error) {
            // console.error('Error copying to clipboard:', error);
        }
    };

    const onAdd = useCallback(
        (newTag) => {
            setSelected([...selected, newTag])
        },
        [selected]
    )
      
    const onDelete = useCallback(
        (tagIndex) => {
            setSelected(selected.filter((_, i) => i !== tagIndex))
        },
        [selected]
    )

    const handlePromptClick = (index) => {
        setDetailPromptIndex(index);
        setShowModal(true);
    };
    
    const handlePrevPrompt = () => {
        const prevIndex = detailPromptIndex - 1;
        setDetailPromptIndex(prevIndex < 0 ? promptsList.length - 1 : prevIndex);  // after the newest news item we go back to the last one
    };
    
    const handleNextPrompt = () => {
        const nextIndex = detailPromptIndex + 1;
        setDetailPromptIndex(nextIndex >= promptsList.length ? 0 : nextIndex);
    };
    
    const handleCloseModal = () => {
        setDetailPromptIndex(null);
        setShowModal(false);
    };

    function DisplayPrompts() {
        if (loadingPrompts) {
            return (
                <div className="py-vh-3 pb-0 h-100 d-flex align-items-center justify-content-center" id='spinner' key='spinner'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) 
        } else { 
            return (
                promptsListFiltered.map((prompt, index) => (   
                    <div className="col-12 py-vh-2 pb-0 prompt-card" onClick={() => handlePromptClick(index)} id={index} key={index} >
                        <h2>{prompt.title}</h2>
                        <div className='w-100 d-flex mb-2'>
                            {
                                (prompt.keywords ? 
                                    prompt.keywords.map((tag) => <div className="float-start prompt-keyword" id={tag}>{tag}</div>)
                                : null)
                            }
                        </div>
                        <p>
                            {
                                prompt.prompt ? 
                                    (prompt.prompt.length > 300 ? 
                                        prompt.prompt.substring(0, 300) + '...'
                                        : prompt.prompt
                                    ) 
                                : null
                            }
                        </p>

                        <button
                        className="btn btn-primary rounded-3 mb-3"
                        onClick={(e) => {
                            e.stopPropagation(); // Stop the propagation of the click event, otherwise we open the prompt details!
                            copyToClipboard(prompt.prompt);
                        }}>
                            {copiedIndex === prompt.prompt 
                            ? <><div className="button-icon"><IoCheckmarkCircle /></div><span className='button-text'>Copied</span></> 
                            : <><div className="button-icon"><IoCopy /></div><span className='button-text'>Copy</span></> }
                        </button>
                    </div>
                ))
            )
        }
    }

    return (
        <>
            <NavbarTop />

            {/* Detailed prompt view modal */}
            {detailPromptIndex !== null && (
                <PromptsDetail
                show={showModal}
                onHide={handleCloseModal}
                promptItem={promptsList[detailPromptIndex]}
                onPrev={handlePrevPrompt}
                onNext={handleNextPrompt}
                />
            )}

            <main>
                <div className="py-vh-4 w-100" id="prompts">
                    <div className="container">
                        <h1 className="display-huge fw-bold pb-5" >
                            GenAI Prompt Ideas
                        </h1>
                        <div className="row">
                            <div className="col-12 col-lg-10 col-xl-8">
                                <ReactTags
                                labelText="Filter tags"
                                placeholderText="Filter tags"
                                selected={selected}
                                suggestions={tagSuggestions}
                                onAdd={onAdd}
                                onDelete={onDelete}
                                noOptionsText="No matching tags"
                                />
                            </div>
                        </div>
                        <div className="w-100 mb-5" id="promptslist">
                            <div className="row">
                                <div className="col-12 col-lg-10 col-xl-8 pb-5">
                                    <DisplayPrompts />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <AdsenseAd dataAdSlot='X7XXXXXX5X' />
            <FooterBottom />
        </>
    );
};