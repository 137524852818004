// PromptsProvider.js
import React, { useState, useEffect } from 'react';
import PromptsContext from '../contexts/PromptsContext';
import { db } from '../config/Firebase';
import { ref, get } from 'firebase/database';

const PromptsProvider = ({ children }) => {
    const [loadingPrompts, setLoadingPrompts] = useState(true);
    const [promptsList, setPromptsList] = useState([]);

    useEffect(() => {
        // NOTE: this is the new firebase v9 web modular api style of a db query
        const promptsRef = ref(db, 'webcontent/prompts/chatgpt');
        get(promptsRef)
        .then((snapshot) => {
            if (snapshot.exists()) {
            const promptsData = snapshot.val();
            if (promptsData) {
                const promptsValues = Object.values(promptsData);
                setPromptsList(promptsValues);
                // console.log('[PromptsProvider] Loaded prompts.')
            }
            } else {
                // console.log("No data available");
            }
        })
        .catch((error) => {
            // console.error(error);
        })
        .finally(() => {
            // Set loading to false after fetching data
            setLoadingPrompts(false); 
        });
    }, []);

    return (
        <PromptsContext.Provider
        value={{ loadingPrompts, promptsList }}
        >
            {children}
        </PromptsContext.Provider>
    );
};

export default PromptsProvider;
