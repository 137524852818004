import React from 'react';
import { useRouteError } from "react-router-dom";
import NavbarTop from './NavbarTop';
import { Link } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    // change document.body.classList on component mount
    // we need to change the classes in index.html body html tag otherwise page is not full height/width because content is less
    document.body.classList.add('h-100');
    document.body.classList.add('w-100');
    

    return (
        <>
            <NavbarTop />
            <main>
            <div className="w-100 overflow-hidden" id="404">

            <div className="container position-relative">
                        <div className="row">
                            <div className="py-vh-6 position-relative">


                            <h1>404</h1>
                            <h1 className="display-1 fw-bold">Oops, something is broken...</h1>
                            <p className="lead">The page you are looking for doesn't exist or has been moved.</p>

                <Link to="/" className="link-fancy">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                    </svg>
                &nbsp;Go back to frontpage</Link>
      </div>
      </div></div></div>
      
      </main>
      </>
  );
}