// PrivacyPage.js
import React from 'react';
import NavbarTop from './NavbarTop';
import FooterBottom from './FooterBottom';

export default function PrivacyPage() {

    function PrivacyPolicy() {

        return(
            <main>
                <div className="py-vh-4 w-100 overflow-hidden" id="privacy">
                    <div className="container">
                        <div className="row d-flex align-items-center position-relative justify-content-center px-0 g-5">
                            <h1 className="display-huge fw-bold">Privacy Policy</h1>

<div><span ><strong><span ><span data-custom-className="subtitle">Last updated February 21, 2024
</span>
</span></strong>
</span></div><div ><span ><span ><span data-custom-className="body_text">This privacy notice for Solon Labs GmbH (doing business as Solon Labs) ("<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"
</span><span data-custom-className="body_text">), describes how and why we might collect, store, use, and/or share ("<strong>process</strong>") your information when you use our services ("<strong>Services</strong>"), such as when you:
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">Visit our website at <span >getaimodels.com
</span><span ><span ><span data-custom-className="body_text"><span ><span >, or any website of ours that links to this privacy notice
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div><span ><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span><ul><li ><span ><span ><span data-custom-className="body_text">Engage with us in other related ways, including any sales, marketing, or events<span ><span ><span data-custom-className="body_text"><span ><span >
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div ><span ><span ><span data-custom-className="body_text"><strong>Questions or concerns?&nbsp;</strong>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at support@getaimodels.com.
</span>
</span>
</span></div><div >
<br /></div><div >
<br /></div><div ><strong><span ><span data-custom-className="heading_1">SUMMARY OF KEY POINTS
</span>
</span></strong></div><div >
<br /></div><div ><span ><span data-custom-className="body_text"><strong><em>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our&nbsp;</em></strong>
</span>
</span><a data-custom-className="link" href="#toc"><span ><span data-custom-className="body_text"><strong><em>table of contents</em></strong>
</span>
</span></a><span ><span data-custom-className="body_text"><strong><em>&nbsp;below to find the section you are looking for.</em></strong>
</span>
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text"><strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about&nbsp;
</span>
</span><a data-custom-className="link" href="#personalinfo"><span ><span data-custom-className="body_text">personal information you disclose to us
</span>
</span></a><span data-custom-className="body_text">.
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text"><strong>Do we process any sensitive personal information?</strong> We do not process sensitive personal information.
</span>
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text"><strong>Do we receive any information from third parties?</strong> We do not receive any information from third parties.
</span>
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text"><strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about&nbsp;
</span>
</span><a data-custom-className="link" href="#infouse"><span ><span data-custom-className="body_text">how we process your information
</span>
</span></a><span data-custom-className="body_text">.
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text"><strong>In what situations and with which types of parties do we share personal information?</strong> We may share information in specific situations and with specific categories of third parties. Learn more about&nbsp;
</span>
</span><a data-custom-className="link" href="#whoshare"><span ><span data-custom-className="body_text">when and with whom we share your personal information
</span>
</span></a><span ><span data-custom-className="body_text">.
</span>
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text"><strong>How do we keep your information safe?</strong> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about&nbsp;
</span>
</span><a data-custom-className="link" href="#infosafe"><span ><span data-custom-className="body_text">how we keep your information safe
</span>
</span></a><span data-custom-className="body_text">.
</span><span ><span data-custom-className="body_text">
</span>
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text"><strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about&nbsp;
</span>
</span><a data-custom-className="link" href="#privacyrights"><span ><span data-custom-className="body_text">your privacy rights
</span>
</span></a><span data-custom-className="body_text">.
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text"><strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by visiting <span >mailto:support@getaimodels.com
</span>, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
</span>
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text">Want to learn more about what we do with any information we collect?&nbsp;
</span>
</span><a data-custom-className="link" href="#toc"><span ><span data-custom-className="body_text">Review the privacy notice in full
</span>
</span></a><span ><span data-custom-className="body_text">.
</span>
</span></div><div >
<br /></div><div >
<br /></div><div id="toc" ><span ><span ><span ><strong><span data-custom-className="heading_1">TABLE OF CONTENTS
</span></strong>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><a data-custom-className="link" href="#infocollect"><span >1. WHAT INFORMATION DO WE COLLECT?
</span></a>
</span></div><div ><span ><a data-custom-className="link" href="#infouse"><span >2. HOW DO WE PROCESS YOUR INFORMATION?
</span></a>
</span></div><div ><span ><a data-custom-className="link" href="#legalbases"><span >3. <span ><span >WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
</span>
</span>
</span></a>
</span></div><div ><span ><span ><a data-custom-className="link" href="#whoshare">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>
</span><span data-custom-className="body_text">
</span>
</span></div><div ><span ><a data-custom-className="link" href="#3pwebsites">5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</a><span ><span ><span data-custom-className="body_text"><span >
</span>
</span>
</span>
</span>
</span></div><div ><span ><a data-custom-className="link" href="#cookies"><span >6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
</span></a><span ><span ><span data-custom-className="body_text"><span >
</span>
</span><span data-custom-className="body_text"><span ><span ><span >
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><div ><span ><a data-custom-className="link" href="#intltransfers"><span >7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
</span></a><span ><span ><span data-custom-className="body_text"><span >
</span>
</span>
</span>
</span>
</span></div><div ><span ><a data-custom-className="link" href="#inforetain"><span >8. HOW LONG DO WE KEEP YOUR INFORMATION?
</span></a><span ><span ><span data-custom-className="body_text"><span ><span >
</span>
</span>
</span>
</span>
</span>
</span></div><div ><span ><a data-custom-className="link" href="#infosafe"><span >9. HOW DO WE KEEP YOUR INFORMATION SAFE?
</span></a><span ><span ><span data-custom-className="body_text"><span >
</span>
</span>
</span>
</span>
</span></div><div ><span ><a data-custom-className="link" href="#infominors"><span >10. DO WE COLLECT INFORMATION FROM MINORS?
</span></a><span ><span ><span data-custom-className="body_text"><span >
</span>
</span>
</span>
</span>
</span></div><div ><span ><span ><a data-custom-className="link" href="#privacyrights">11. WHAT ARE YOUR PRIVACY RIGHTS?</a>
</span>
</span></div><div ><span ><a data-custom-className="link" href="#DNT"><span >12. CONTROLS FOR DO-NOT-TRACK FEATURES
</span></a>
</span></div><div ><span ><a data-custom-className="link" href="#uslaws"><span >13. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
</span></a>
</span><span ><span data-custom-className="body_text">
</span>
</span></div><div ><a data-custom-className="link" href="#otherlaws"><span >14. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
</span></a><span ><span data-custom-className="body_text">
</span>
</span></div><div ><span >
</span></div><div ><span ><a data-custom-className="link" href="#policyupdates"><span >15. DO WE MAKE UPDATES TO THIS NOTICE?
</span></a>
</span></div><div ><a data-custom-className="link" href="#contact"><span >16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
</span></a></div><div ><a data-custom-className="link" href="#request"><span >17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
</span></a></div><div >
<br /></div><div >
<br /></div><div id="infocollect" ><span ><span ><span ><span ><span id="control" ><strong><span data-custom-className="heading_1">1. WHAT INFORMATION DO WE COLLECT?
</span></strong>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div id="personalinfo" ><span data-custom-className="heading_2" ><span ><strong>Personal information you disclose to us</strong>
</span>
</span></div><div><div>
<br /></div><div ><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><strong><em>In Short:</em></strong>
</span>
</span>
</span>
</span><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><strong><em>&nbsp;</em></strong><em>We collect personal information that you provide to us.</em>
</span>
</span>
</span>
</span>
</span>
</span></div></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">We collect personal information that you voluntarily provide to us when you <span >
</span>
</span><span data-custom-className="body_text">express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><span >
</span>
</span>
</span>
</span></div><div id="sensitiveinfo" ><span ><span data-custom-className="body_text"><strong>Sensitive Information.</strong> We do not process sensitive information.
</span>
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text">
</span>
</span><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span></div><div ><span ><span ><span data-custom-className="body_text">All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span></div><div ><span data-custom-className="heading_2" ><span ><strong>Information automatically collected</strong>
</span>
</span></div><div><div>
<br /></div><div ><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><strong><em>In Short:</em></strong>
</span>
</span>
</span>
</span><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><strong><em>&nbsp;</em></strong><em>Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</em>
</span>
</span>
</span>
</span>
</span>
</span></div></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span></div><div ><span ><span ><span data-custom-className="body_text">Like many businesses, we also collect information through cookies and similar technologies. You can find out more about this in our Cookie Notice: <span >getaimodels.com/cookies
</span>.
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><span data-custom-className="body_text">
</span>
</span><span data-custom-className="body_text">
</span>
</span>
</span></div><div ><span ><span ><span data-custom-className="body_text">The information we collect includes:
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text"><em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services<span >&nbsp;
</span>(such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).<span ><span ><span data-custom-className="body_text"><span ><span >
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text"><em>Device Data.</em> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.<span ><span ><span data-custom-className="body_text"><span ><span >
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text"><em>Location Data.</em> We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.<span ><span ><span data-custom-className="body_text"><span ><span >
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div><span >
</span><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span></div><div id="infouse" ><span ><span ><span ><span ><span id="control" ><strong><span data-custom-className="heading_1">2. HOW DO WE PROCESS YOUR INFORMATION?
</span></strong>
</span>
</span>
</span>
</span>
</span></div><div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em>
</span>
</span>
</span>
</span>
</span>
</span></div></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong>
</span>
</span>
</span><div ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span><div ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span><div ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span><div ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span><div ><span >
</span><div ><span >
</span><div ><span >
</span><div ><span ><span data-custom-className="body_text">
</span>
</span><p ><span >
</span></p><p ><span >
</span></p><p ></p><p ></p><ul><li ><span ><span ><span ><span data-custom-className="body_text"><strong>To request feedback.&nbsp;</strong>We may process your information when necessary to request feedback and to contact you about your use of our Services.<span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span><div ><span >
</span><div ><span >
</span><div ><span ><span data-custom-className="body_text">
</span>
</span></div><ul><li ><span ><span data-custom-className="body_text"><strong>To deliver targeted advertising to you.</strong> We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more. For more information see our Cookie Notice: <span >getaimodels.com/cookies
</span>.
</span>
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span><div ><span ><span data-custom-className="body_text">
</span>
</span><div ><span ><span data-custom-className="body_text">
</span>
</span></div><ul><li ><span data-custom-className="body_text"><span ><strong>To protect our Services.</strong> We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span><div ><span ><span data-custom-className="body_text">
</span>
</span><div ><span ><span data-custom-className="body_text">
</span>
</span><div ><span ><span data-custom-className="body_text">
</span>
</span></div><ul><li ><span data-custom-className="body_text"><span ><strong>To identify usage trends.</strong> We may process information about how you use our Services to better understand how they are being used so we can improve them.
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span><div ><span ><span data-custom-className="body_text">
</span>
</span></div><ul><li ><span ><span data-custom-className="body_text"><strong>To determine the effectiveness of our marketing and promotional campaigns.</strong> We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.
</span>
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span><div ><span ><span data-custom-className="body_text">
</span>
</span></div><ul><li ><span data-custom-className="body_text"><span ><strong>To save or protect an individual's vital interest.</strong> We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span></div><div >
<br /></div><div id="legalbases" ><strong><span ><span data-custom-className="heading_1">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
</span>
</span></strong></div><div >
<br /></div><div ><em><span ><span data-custom-className="body_text"><strong>In Short:&nbsp;</strong>We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.
</span>
</span></em><span ><span data-custom-className="body_text">
</span><span data-custom-className="body_text">
</span>
</span></div><div >
<br /></div><div ><em><span ><span data-custom-className="body_text"><strong><u>If you are located in the EU or UK, this section applies to you.</u></strong>
</span>
</span></em><span ><span data-custom-className="body_text">
</span>
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text">The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
</span>
</span></div><ul><li ><span ><span data-custom-className="body_text"><strong>Consent.&nbsp;</strong>We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about&nbsp;
</span>
</span><a data-custom-className="link" href="#withdrawconsent"><span ><span data-custom-className="body_text">withdrawing your consent
</span>
</span></a><span data-custom-className="body_text">.
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span><div ><span ><span data-custom-className="body_text">
</span>
</span></div><ul><li ><span data-custom-className="body_text"><span ><strong>Legitimate Interests.</strong> We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:
</span>
</span></li></ul><div ><span data-custom-className="body_text"><span >
</span>
</span><div ><span data-custom-className="body_text"><span >
</span>
</span></div><ul ><li ><span data-custom-className="body_text"><span >Develop and display personalized and relevant advertising content for our users
</span>
</span></li></ul><div ><span data-custom-className="body_text"><span >
</span>
</span></div><ul ><li ><span data-custom-className="body_text"><span >Analyze how our Services are used so we can improve them to engage and retain users
</span>
</span></li></ul><div ><span data-custom-className="body_text"><span >
</span>
</span></div><ul ><li ><span data-custom-className="body_text"><span >Support our marketing activities
</span>
</span></li></ul><div ><span data-custom-className="body_text"><span >
</span>
</span></div><ul ><li ><span data-custom-className="body_text"><span >Diagnose problems and/or prevent fraudulent activities
</span>
</span></li></ul><div ><span data-custom-className="body_text"><span >
</span>
</span></div><ul ><li ><span data-custom-className="body_text"><span >Understand how our users use our products and services so we can improve user experience
</span>
</span></li></ul><div ><span data-custom-className="body_text"><span >
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span></div><ul><li ><span data-custom-className="body_text"><span ><strong>Legal Obligations.</strong> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
<br />
</span>
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span></div><ul><li ><span data-custom-className="body_text"><span ><strong>Vital Interests.</strong> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span><span data-custom-className="body_text"><span >
</span>
</span></div><div >
<br /></div><div ><span data-custom-className="body_text"><span ><strong><u><em>If you are located in Canada, this section applies to you.</em></u></strong>
</span>
</span></div><div >
<br /></div><div ><span data-custom-className="body_text"><span >We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can&nbsp;
</span>
</span><a data-custom-className="link" href="#withdrawconsent"><span data-custom-className="body_text"><span >withdraw your consent
</span>
</span></a><span data-custom-className="body_text"><span >&nbsp;at any time.
</span>
</span></div><div >
<br /></div><div ><span data-custom-className="body_text"><span >In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
</span>
</span></div><ul><li ><span data-custom-className="body_text"><span >If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way
</span>
</span></li></ul><div ><span data-custom-className="body_text"><span >
</span>
</span></div><ul><li ><span data-custom-className="body_text"><span >For investigations and fraud detection and prevention
</span>
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span></div><ul><li ><span data-custom-className="body_text"><span >For business transactions provided certain conditions are met
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span></div><ul><li ><span data-custom-className="body_text"><span >If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span></div><ul><li ><span data-custom-className="body_text"><span >For identifying injured, ill, or deceased persons and communicating with next of kin
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span></li></ul><div ><span data-custom-className="body_text"><span >
</span>
</span></div><ul><li ><span data-custom-className="body_text"><span >If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse
</span>
</span></li></ul><div ><span data-custom-className="body_text"><span >
</span>
</span></div><ul><li ><span data-custom-className="body_text"><span >If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province
</span>
</span></li></ul><div ><span data-custom-className="body_text"><span >
</span>
</span></div><ul><li ><span data-custom-className="body_text"><span >If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records
</span>
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span></div><ul><li ><span ><span data-custom-className="body_text">If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced
</span>
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span></div><ul><li ><span ><span data-custom-className="body_text">If the collection is solely for journalistic, artistic, or literary purposes
</span>
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span></div><ul><li ><span ><span data-custom-className="body_text">If the information is publicly available and is specified by the regulations
</span><span data-custom-className="body_text">
</span>
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span></div><div >
<br /></div><div id="whoshare" ><span ><span ><span ><span ><span id="control" ><strong><span data-custom-className="heading_1">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
</span></strong>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We may share information in specific situations described in this section and/or with the following categories of third parties.</em>
</span>
</span>
</span></div><div ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text"><strong>Vendors, Consultants, and Other Third-Party Service Providers.</strong> We may share your data with third-party vendors, service providers, contractors, or agents ("<strong>third parties</strong>") who perform services for us or on our behalf and require access to such information to do that work. We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organization apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct. The categories of third parties we may share personal information with are as follows:
</span>
</span><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">Ad Networks
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">Cloud Computing Services
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">Data Analytics Services
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">Data Storage Service Providers
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">Payment Processors
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">Performance Monitoring Tools
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">User Account Registration &amp; Authentication Services
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">Website Hosting Service Providers
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">Sales &amp; Marketing Tools
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span ><span ><span ><span >
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span><span data-custom-className="body_text">
</span>
</span>
</span><span data-custom-className="body_text">
</span><span data-custom-className="body_text">
</span><span data-custom-className="body_text">
</span><span data-custom-className="body_text">
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text">We also may need to share your personal information in the following situations:
</span>
</span></div><ul><li ><span ><span data-custom-className="body_text"><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
</span>
</span></li></ul><div ><span >
</span></div><ul><li ><span ><strong><span data-custom-className="body_text">When we use Google Analytics.
</span></strong><span data-custom-className="body_text">&nbsp;We may share your information with Google Analytics to track and analyze the use of the Services. To opt out of being tracked by Google Analytics across the Services, visit <span ><a data-custom-className="link" href="https://tools.google.com/dlpage/gaoptout" rel="noopener noreferrer" target="_blank">https://tools.google.com/dlpage/gaoptout</a>
</span>. For more information on the privacy practices of Google, please visit the <span ><a data-custom-className="link" href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">Google Privacy &amp; Terms page</a>
</span>.
</span>
</span><span >
</span></li></ul><div ><span ><span data-custom-className="body_text">
</span>
</span><div ><span ><span data-custom-className="body_text">
</span>
</span><div ><span ><span data-custom-className="body_text">
</span>
</span><div ><span ><span data-custom-className="body_text">
</span>
</span><div ><span ><span data-custom-className="body_text">
</span>
</span><div ><span ><span data-custom-className="body_text">
</span>
</span></div><div >
<br /></div><div ><strong><span id="3pwebsites" ><span data-custom-className="heading_1">5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
</span>
</span></strong></div><div >
<br /></div><div ><span ><span data-custom-className="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services, but are not affiliated with, our Services.</em>
</span>
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text">The Services may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or applications. The inclusion of a link towards a third-party website, service, or application does not imply an endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions.
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span><span ><span ><span ><span ><span ><span data-custom-className="heading_1">
</span>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div id="cookies" ><span ><span ><span ><span ><span id="control" ><strong><span data-custom-className="heading_1">6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
</span></strong>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We may use cookies and other tracking technologies to collect and store your information.</em>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice<span ><span data-custom-className="body_text">
</span>
</span>: <span ><span data-custom-className="body_text">getaimodels.com/cookies
</span>
</span><span >.
</span><span ><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span><span data-custom-className="body_text"><span ><span ><span ><span ><span ><span ><span ><span data-custom-className="body_text">
</span><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div id="intltransfers" ><span ><span ><span ><span ><span id="control" ><strong><span data-custom-className="heading_1">7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
</span></strong>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We may transfer, store, and process your information in countries other than your own.</em>
</span>
</span>
</span></div><div >
<br /></div><div data-custom-className="body_text" ><span ><span ><span data-custom-className="body_text">Our servers are located in<span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span> the <span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">United States
</span>
</span>
</span>
</span><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span><span ><span ><span data-custom-className="body_text">. If you are accessing our Services from outside
</span><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span> the <span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">United States
</span>
</span>
</span>
</span><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span><span data-custom-className="body_text">, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "
</span><a data-custom-className="link" href="#whoshare"><span ><span >WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
</span>
</span></a><span ><span ><span data-custom-className="body_text">" above), in
</span>
</span>
</span><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span> the <span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">United States,
</span>
</span>
</span>
</span><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span><span ><span ><span data-custom-className="body_text">&nbsp;and other countries.
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.<span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">European Commission's Standard Contractual Clauses:
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">We have implemented measures to protect your personal information, including by using the European Commission's Standard Contractual Clauses for transfers of personal information between our group companies and between us and our third-party providers. These clauses require all recipients to protect all personal information that they process originating from the EEA or UK in accordance with European data protection laws and regulations.<span ><span ><span data-custom-className="body_text">
</span>
</span>&nbsp;
</span>Our Standard Contractual Clauses can be provided upon request.<span ><span ><span data-custom-className="body_text">
</span>
</span>&nbsp;
</span>We have implemented similar appropriate safeguards with our third-party service providers and partners and further details can be provided upon request.<span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span><span ><span ><span data-custom-className="body_text"><span ><span ><span ><span ><span ><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div id="inforetain" ><span ><span ><span ><span ><span id="control" ><strong><span data-custom-className="heading_1">8. HOW LONG DO WE KEEP YOUR INFORMATION?
</span></strong>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</em>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.<span >
</span>
</span>
</span>
</span></div><div >
<br /></div><div id="infosafe" ><span ><span ><span ><span ><span id="control" ><strong><span data-custom-className="heading_1">9. HOW DO WE KEEP YOUR INFORMATION SAFE?
</span></strong>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We aim to protect your personal information through a system of organizational and technical security measures.</em>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.<span >
</span><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div id="infominors" ><span ><span ><span ><span ><span id="control" ><strong><span data-custom-className="heading_1">10. DO WE COLLECT INFORMATION FROM MINORS?
</span></strong>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We do not knowingly collect data from or market to children under 18 years of age.</em>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <span ><span data-custom-className="body_text">support@getaimodels.com
</span>
</span>.
</span><span data-custom-className="body_text">
</span>
</span>
</span></div><div >
<br /></div><div id="privacyrights" ><span ><span ><span ><span ><span id="control" ><strong><span data-custom-className="heading_1">11. WHAT ARE YOUR PRIVACY RIGHTS?
</span></strong>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><strong><em>In Short:</em></strong><em>&nbsp;<span ><span ><span data-custom-className="body_text"><em></em>
</span>
</span>
</span>In some regions, such as the European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights that allow you greater access to and control over your personal information.<span ><span ><span data-custom-className="body_text"><em></em>
</span>
</span>&nbsp;
</span>You may review, change, or terminate your account at any time.</em><span ><span >
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section "
</span>
</span>
</span><a data-custom-className="link" href="#contact"><span ><span ><span data-custom-className="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
</span>
</span>
</span></a><span ><span ><span data-custom-className="body_text">" below.
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">We will consider and act upon any request in accordance with applicable data protection laws.
</span>
</span>
</span></div><div ><span >&nbsp;
</span></div><div ><span ><span ><span data-custom-className="body_text">If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your <span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><a data-custom-className="link" href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" rel="noopener noreferrer" target="_blank"><span >Member State data protection authority
</span></a>
</span>
</span>
</span>
</span>
</span> or&nbsp;
</span>
</span>
</span><a data-custom-className="link" href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/" rel="noopener noreferrer" target="_blank"><span ><span ><span data-custom-className="body_text">UK data protection authority
</span>
</span>
</span></a><span ><span ><span data-custom-className="body_text">.
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">If you are located in Switzerland, you may contact the <span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><span ><a data-custom-className="link" href="https://www.edoeb.admin.ch/edoeb/en/home.html" rel="noopener noreferrer" target="_blank">Federal Data Protection and Information Commissioner</a>
</span>
</span>
</span>
</span>
</span>
</span>.
</span>
</span>
</span></div><div >
<br /></div><div id="withdrawconsent" ><span ><span ><span data-custom-className="body_text"><strong><u>Withdrawing your consent:</u></strong> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "
</span>
</span>
</span><a data-custom-className="link" href="#contact"><span ><span ><span data-custom-className="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
</span>
</span>
</span></a><span ><span ><span data-custom-className="body_text">" below.
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text">However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span><span ><span ><span data-custom-className="body_text"><span ><span ><span ><span ><span data-custom-className="body_text"><span ><span >
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><strong><u>Cookies and similar technologies:</u></strong> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. You may also <span ><span data-custom-className="body_text"><a data-custom-className="link" href="http://www.aboutads.info/choices/" rel="noopener noreferrer" target="_blank"><span >opt out of interest-based advertising by advertisers
</span></a>
</span>
</span> on our Services. <span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>For further information, please see our Cookie Notice: <span ><span ><span data-custom-className="body_text"><span >getaimodels.com/cookies
</span>.<span ><span ><span ><span ><span ><span >
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span></div><div >
<br /></div><div ><span data-custom-className="body_text"><span >If you have questions or comments about your privacy rights, you may email us at support@getaimodels.com.
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span></div><div >
<br /></div><div id="DNT" ><span ><span ><span ><span ><span id="control" ><strong><span data-custom-className="heading_1">12. CONTROLS FOR DO-NOT-TRACK FEATURES
</span></strong>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
</span>
</span>
</span></div><div >
<br /></div><div id="uslaws" ><span ><span ><span ><span ><span id="control" ><strong><span data-custom-className="heading_1">13. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
</span></strong>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><strong><em>In Short:&nbsp;</em></strong><em>If you are a resident of  California, Colorado, Connecticut, Utah or Virginia, you are granted specific rights regarding access to your personal information.</em>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><strong>What categories of personal information do we collect?</strong>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">We have collected the following categories of personal information in the past twelve (12) months:
</span>
</span>
</span></div><div >
<br /></div><table ><tbody><tr><td ><span ><span ><span data-custom-className="body_text"><strong>Category</strong>
</span>
</span>
</span></td><td ><span ><span ><span data-custom-className="body_text"><strong>Examples</strong>
</span>
</span>
</span></td><td ><span ><span ><span data-custom-className="body_text"><strong>Collected</strong>
</span>
</span>
</span></td></tr><tr><td ><div ><span ><span ><span data-custom-className="body_text">A. Identifiers
</span>
</span>
</span></div></td><td ><div ><span ><span ><span data-custom-className="body_text">Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name
</span>
</span>
</span></div></td><td ><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">NO
</span><span data-custom-className="body_text">
</span>
</span>
</span></div><div >
<br /></div></td></tr></tbody></table><div ></div><table ><tbody><tr><td ><div ><span ><span ><span data-custom-className="body_text">B. Personal information as defined in the California Customer Records statute
</span>
</span>
</span></div></td><td ><div ><span ><span ><span data-custom-className="body_text">Name, contact information, education, employment, employment history, and financial information
</span>
</span>
</span></div></td><td ><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span></div><div >
<br /></div></td></tr></tbody></table><div ></div><table ><tbody><tr><td ><div ><span ><span ><span data-custom-className="body_text">C. Protected classification characteristics under state or federal law
</span>
</span>
</span></div></td><td ><div ><span ><span ><span data-custom-className="body_text">Gender and date of birth
</span>
</span>
</span></div></td><td ><div >
<br /></div><div data-custom-className="body_text" ><span data-custom-className="body_text">
</span>NO<span data-custom-className="body_text">
</span></div><div >
<br /></div></td></tr><tr><td ><div ><span ><span ><span data-custom-className="body_text">D. Commercial information
</span>
</span>
</span></div></td><td ><div ><span ><span ><span data-custom-className="body_text">Transaction information, purchase history, financial details, and payment information
</span>
</span>
</span></div></td><td ><div >
<br /></div><div data-custom-className="body_text" ><span data-custom-className="body_text">
</span>NO<span data-custom-className="body_text">
</span></div><div >
<br /></div></td></tr><tr><td ><div ><span ><span ><span data-custom-className="body_text">E. Biometric information
</span>
</span>
</span></div></td><td ><div ><span ><span ><span data-custom-className="body_text">Fingerprints and voiceprints
</span>
</span>
</span></div></td><td ><div >
<br /></div><div data-custom-className="body_text" ><span data-custom-className="body_text">
</span>NO<span data-custom-className="body_text">
</span></div><div >
<br /></div></td></tr><tr><td ><div ><span ><span ><span data-custom-className="body_text">F. Internet or other similar network activity
</span>
</span>
</span></div></td><td ><div ><span ><span ><span data-custom-className="body_text">Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements
</span>
</span>
</span></div></td><td ><div >
<br /></div><div data-custom-className="body_text" ><span data-custom-className="body_text">
</span><span data-custom-className="body_text">
</span><span data-custom-className="body_text">YES
</span></div><div >
<br /></div></td></tr><tr><td ><div ><span ><span ><span data-custom-className="body_text">G. Geolocation data
</span>
</span>
</span></div></td><td ><div ><span ><span ><span data-custom-className="body_text">Device location
</span>
</span>
</span></div></td><td ><div >
<br /></div><div data-custom-className="body_text" ><span data-custom-className="body_text">
</span>NO<span data-custom-className="body_text">
</span></div><div >
<br /></div></td></tr><tr><td ><div ><span ><span ><span data-custom-className="body_text">H. Audio, electronic, visual, thermal, olfactory, or similar information
</span>
</span>
</span></div></td><td ><div ><span ><span ><span data-custom-className="body_text">Images and audio, video or call recordings created in connection with our business activities
</span>
</span>
</span></div></td><td ><div >
<br /></div><div data-custom-className="body_text" ><span data-custom-className="body_text">
</span>NO<span data-custom-className="body_text">
</span></div><div >
<br /></div></td></tr><tr><td ><div ><span ><span ><span data-custom-className="body_text">I. Professional or employment-related information
</span>
</span>
</span></div></td><td ><div ><span ><span ><span data-custom-className="body_text">Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us
</span>
</span>
</span></div></td><td ><div >
<br /></div><div data-custom-className="body_text" ><span data-custom-className="body_text">
</span>NO<span data-custom-className="body_text">
</span></div><div >
<br /></div></td></tr><tr><td ><div ><span ><span ><span data-custom-className="body_text">J. Education Information
</span>
</span>
</span></div></td><td ><div ><span ><span ><span data-custom-className="body_text">Student records and directory information
</span>
</span>
</span></div></td><td ><div >
<br /></div><div data-custom-className="body_text" ><span data-custom-className="body_text">
</span>NO<span data-custom-className="body_text">
</span></div><div >
<br /></div></td></tr><tr><td ><div ><span ><span ><span data-custom-className="body_text">K. Inferences drawn from collected personal information
</span>
</span>
</span></div></td><td ><div ><span ><span ><span data-custom-className="body_text">Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics
</span>
</span>
</span></div></td><td ><div >
<br /></div><div data-custom-className="body_text" ><span data-custom-className="body_text">NO<span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div></td></tr><tr><td ><span data-custom-className="body_text">L. Sensitive personal Information
</span></td><td ><span data-custom-className="body_text">
</span></td><td ><div data-empty="true" >
<br /></div><div data-custom-className="body_text" data-empty="true" ><span data-custom-className="body_text">
</span>NO<span data-custom-className="body_text">
</span></div><div data-empty="true" >
<br /></div></td></tr></tbody></table><div >
<br /></div><div ><span data-custom-className="body_text">
</span><span data-custom-className="body_text">We will use and retain the collected personal information as needed to provide the Services or for:
</span><div ><span data-custom-className="body_text">
</span></div><ul><li ><span data-custom-className="body_text">Category B - As long as the user has an account with us
</span></li></ul><div ><span data-custom-className="body_text">
</span><div ><span data-custom-className="body_text">
</span><div ><span data-custom-className="body_text">
</span><div ><span data-custom-className="body_text">
</span></div><ul><li ><span data-custom-className="body_text">Category F - As long as the user has an account with us
</span></li></ul><div ><span data-custom-className="body_text">
</span><div ><span data-custom-className="body_text">
</span><div ><span data-custom-className="body_text">
</span><div ><span data-custom-className="body_text">
</span><div ><span data-custom-className="body_text">
</span><div ><span data-custom-className="body_text">
</span><span data-custom-className="body_text">
</span><div ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span></div><div ><span ><span ><span data-custom-className="body_text">We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:<span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">Receiving help through our customer support channels;
</span><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">Participation in customer surveys or contests; and
</span><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">Facilitation in the delivery of our Services and to respond to your inquiries.
</span><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div ><span ><span ><span data-custom-className="body_text"><strong>How do we use and share your personal information?</strong>
</span>
</span>
</span></div><div >
<br /></div><div ><span data-custom-className="body_text" >Learn about how we use your personal information in the section, "
</span><a data-custom-className="link" href="#infouse"><span >HOW DO WE PROCESS YOUR INFORMATION?
</span></a><span data-custom-className="body_text" >"
</span><span data-custom-className="body_text" >
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span></div><div ><span ><span ><span data-custom-className="body_text">We collect and share your personal information through:<span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">Targeting cookies/Marketing cookies<span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span><div><span ><span ><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span><div><span ><span ><span ><span ><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span><div><span ><span ><span ><span ><span ><span ><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span><div><span ><span ><span ><span ><span ><span ><span ><span ><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span><span data-custom-className="body_text"><span ><span ><span ><span ><span ><span ><span ><span ><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span><span data-custom-className="body_text"><span ><span ><span ><span ><span ><span ><span ><span ><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><div ><span ><span ><span data-custom-className="body_text"><strong>Will your information be shared with anyone else?</strong>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, "
</span>
</span>
</span><a data-custom-className="link" href="#whoshare"><span ><span >WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
</span>
</span></a><span ><span ><span data-custom-className="body_text">"
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.<span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text">
</span>
</span><span data-custom-className="body_text"><span >We have not sold or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months.&nbsp;
</span>
</span><span ><span data-custom-className="body_text">
</span>
</span><span ><span ><span ><span data-custom-className="body_text">We have disclosed the following categories of personal information to third parties for a business or commercial purpose in the preceding twelve (12) months:
</span>
</span>
</span>
</span><p ><span >
</span></p><p ><span data-custom-className="body_text">
</span></p><p ><span data-custom-className="body_text">
</span></p><p ><span >
</span></p><p ><span >
</span></p><p ><span >
</span></p><p ><span >
</span></p><p ><span >
</span></p><p ><span >
</span></p><p ><span >
</span></p><div ><span >
</span><span >
</span></div><ul><li ><span ><span ><span ><span data-custom-className="body_text">Category F. Internet or other electronic network activity information<span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><span >
</span>
</span>
</span>
</span><span data-custom-className="body_text"><span >
</span><span >
</span>
</span>
</span>
</span>
</span><span data-custom-className="body_text"><span >
</span>
</span>
</span>
</span></li></ul><p ></p><p ></p><p ><span >
</span></p><p ><span >
</span></p><div><span >
</span><div><span >
</span><div><span >
</span><div><span >
</span><div><span >
</span><div><div ><span ><span ><span data-custom-className="body_text">The categories of third parties to whom we disclosed personal information for a business or commercial purpose can be found under "<span ><span ><span ><a data-custom-className="link" href="#whoshare">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>
</span><span ><span ><span ><span ><span ><span ><span data-custom-className="body_text"><span >"
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span><span data-custom-className="body_text"><span ><span ><span ><span ><span ><span ><span ><span ><span data-custom-className="body_text"><span ><span ><span ><span ><span ><span ><span ><span ><span ><span data-custom-className="body_text"><span ><span ><span ><span ><span ><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><strong><span data-custom-className="heading_2" >California Residents
</span></strong></div><div >
<br /></div><div ><span data-custom-className="body_text" >California Civil Code Section 1798.83, also known as the "Shine The Light" law permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
</span></div><div ><span ><span data-custom-className="body_text">
<br />
</span>
</span></div><div ><span data-custom-className="body_text" >If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).
</span></div><div ><span >
<br />
</span></div><div ><span ><span data-custom-className="body_text"><strong><u>CCPA Privacy Notice</u></strong>
</span>
</span></div><div ><span ><span data-custom-className="body_text">
<br />
</span>
</span></div><div ><span ><span data-custom-className="body_text">This section applies only to California residents. Under the California Consumer Privacy Act (CCPA), you have the rights listed below.
</span>
</span></div><div ><span ><span data-custom-className="body_text">
<br />
</span>
</span></div><div ><span ><span data-custom-className="body_text">The California Code of Regulations defines a "residents" as:
</span>
</span></div><div ><span ><span data-custom-className="body_text">
<br />
</span>
</span></div><div ><span ><span data-custom-className="body_text">(1) every individual who is in the State of California for other than a temporary or transitory purpose and
</span>
</span></div><div ><span ><span data-custom-className="body_text">(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose
</span>
</span></div><div ><span ><span data-custom-className="body_text">
<br />
</span>
</span></div><div ><span ><span data-custom-className="body_text">All other individuals are defined as "non-residents."
</span>
</span></div><div ><span ><span data-custom-className="body_text">
<br />
</span>
</span></div><div ><span ><span data-custom-className="body_text">If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><strong>Your rights with respect to your personal data</strong>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><u>Right to request deletion of the data — Request to delete</u>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><u>Right to be informed — Request to know</u>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">Depending on the circumstances, you have a right to know:<span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">whether we collect and use your personal information;<span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">the categories of personal information that we collect;<span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">the purposes for which the collected personal information is used;<span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">whether we sell or share personal information to third parties;<span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">the categories of personal information that we sold, shared, or disclosed for a business purpose;<span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;<span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">the business or commercial purpose for collecting, selling, or sharing personal information; and<span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div ><span data-custom-className="body_text">
</span></div><ul><li ><span data-custom-className="body_text">the specific pieces of personal information we collected about you.
</span></li></ul><div ><span ><span ><span data-custom-className="body_text">In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><u>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</u>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">We will not discriminate against you if you exercise your privacy rights.
</span>
</span>
</span></div><div >
<br /></div><div ><span ><u><span data-custom-className="body_text">Right to Limit Use and Disclosure of Sensitive Personal Information
</span></u>
</span></div><div ><span data-custom-className="body_text">
</span></div><div >
<br /></div><div ><span data-custom-className="body_text">We do not process consumer's sensitive personal information.
</span></div><div >
<br /></div><div ><span data-custom-className="body_text">
</span></div><div ><span ><span ><span data-custom-className="body_text"><u>Verification process</u>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><u>Other privacy rights</u>
</span>
</span>
</span></div><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">You may object to the processing of your personal information.<span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.<span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.<span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><ul><li ><span ><span ><span data-custom-className="body_text">You may request to opt out from future selling or sharing of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.<span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span></li></ul><div ><span >
</span></div><div ><span ><span ><span data-custom-className="body_text">To exercise these rights, you can contact us by visiting <span >mailto:support@getaimodels.com
</span>, 
</span><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">by email at support@getaimodels.com, 
</span><span data-custom-className="body_text"><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span><span data-custom-className="body_text">or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.
</span><span ><span data-custom-className="body_text">
</span>
</span></div><div ><span >
</span></div><div ><span >
<br />
</span></div><div ><span ><strong><span data-custom-className="heading_2">Colorado Residents
</span></strong>
</span></div><div ><span >
<br />
</span></div><div ><span ><span data-custom-className="body_text">This section applies only to Colorado residents. Under the Colorado Privacy Act (CPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.
</span>
</span></div><ul><li ><span ><span data-custom-className="body_text">Right to be informed whether or not we are processing your personal data
</span>
</span></li></ul><div ><span >
</span></div><ul><li ><span ><span data-custom-className="body_text">Right to access your personal data
</span>
</span></li></ul><div ></div><ul><li ><span ><span data-custom-className="body_text">Right to correct inaccuracies in your personal data
</span>
</span></li></ul><div ></div><ul><li ><span ><span data-custom-className="body_text">Right to request deletion of your personal data
</span>
</span></li></ul><div ><span >
</span></div><ul><li ><span ><span data-custom-className="body_text">Right to obtain a copy of the personal data you previously shared with us
</span>
</span></li></ul><div ></div><ul><li ><span ><span data-custom-className="body_text">Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")
</span>
</span></li></ul><div data-custom-className="body_text" ><span data-custom-className="body_text">
</span><span >To submit a request to exercise
</span> <span >these
</span> <span >rights described above, please email
</span> support@getaimodels.com<span >&nbsp;or&nbsp;
</span><span >visit
</span> <span >mailto:support@getaimodels.com
</span><span ><span data-custom-className="body_text">.
</span>
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text">If we decline to take action regarding your request and you wish to appeal our decision, please email us at support@getaimodels.com. Within forty-five (45) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions.
</span>
</span></div><div ></div><div >
<br /></div><div ><strong><span ><span data-custom-className="heading_2">Connecticut Residents
</span>
</span></strong></div><div ><span >
<br />
</span></div><div ><span ><span data-custom-className="body_text">This section applies only to Connecticut residents. Under the Connecticut Data Privacy Act (CTDPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.
</span>
</span></div><ul><li ><span ><span data-custom-className="body_text">Right to be informed whether or not we are processing your personal data
</span>
</span></li></ul><div ></div><ul><li ><span data-custom-className="body_text">Right to access your personal data
</span></li></ul><div ><span >
</span></div><ul><li ><span ><span data-custom-className="body_text">Right to correct inaccuracies in your personal data
</span>
</span></li></ul><div ></div><ul><li ><span ><span data-custom-className="body_text">Right to request deletion of your personal data
</span>
</span></li></ul><div ><span >
</span></div><ul><li ><span ><span data-custom-className="body_text">Right to obtain a copy of the personal data you previously shared with us
</span>
</span></li></ul><div ></div><ul><li ><span ><span data-custom-className="body_text">Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")
</span>
</span></li></ul><div data-custom-className="body_text" ><span ><span data-custom-className="body_text">
</span>
</span>To submit a request to exercise these rights described above, please email support@getaimodels.com or visit <span >mailto:support@getaimodels.com
</span>.</div><div >
<br /></div><div ><span ><span data-custom-className="body_text">If we decline to take action regarding your request and you wish to appeal our decision, please email us at support@getaimodels.com. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions.
</span>
</span><span >
</span></div><div ><span >
<br />
</span></div><div ><span ><strong><span data-custom-className="heading_2">Utah Residents
</span></strong>
</span></div><div ><span >
<br />
</span></div><div ><span ><span data-custom-className="body_text">This section applies only to Utah residents. Under the Utah Consumer Privacy Act (UCPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.
</span>
</span></div><ul><li ><span ><span data-custom-className="body_text">Right to be informed whether or not we are processing your personal data
</span>
</span></li></ul><div ><span >
</span></div><ul><li ><span ><span data-custom-className="body_text">Right to access your personal data
</span>
</span></li></ul><div ><span >
</span></div><ul><li ><span ><span data-custom-className="body_text">Right to request deletion of your personal data
</span>
</span></li></ul><div ><span >
</span></div><ul><li ><span ><span data-custom-className="body_text">Right to obtain a copy of the personal data you previously shared with us
</span>
</span></li></ul><div ><span >
</span></div><ul><li ><span ><span data-custom-className="body_text">Right to opt out of the processing of your personal data if it is used for targeted advertising or the sale of personal data
</span>
</span></li></ul><div data-custom-className="body_text" ><span ><span data-custom-className="body_text">
</span>To submit a request to exercise these rights described above, please email support@getaimodels.com or visit <span >mailto:support@getaimodels.com
</span>.
</span><span >
</span><span >
</span></div><div ><span >
<br />
</span></div><div ><span ><strong><span data-custom-className="heading_2">Virginia Residents
</span></strong>
</span></div><div ><span >
<br />
</span></div><div ><span ><span data-custom-className="body_text">Under the Virginia Consumer Data Protection Act (VCDPA):
</span>
</span></div><div ><span >
<br />
</span></div><div data-custom-className="body_text" ><span ><span data-custom-className="body_text">
</span>"Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.
</span></div><div ><span >
<br />
</span></div><div data-custom-className="body_text" ><span ><span data-custom-className="body_text">
</span>"Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person. "Personal data" does not include de-identified data or publicly available information.
</span></div><div ><span >
<br />
</span></div><div data-custom-className="body_text" ><span ><span data-custom-className="body_text">
</span>"Sale of personal data" means the exchange of personal data for monetary consideration.
</span></div><div ><span >
<br />
</span></div><div ><span ><span data-custom-className="body_text">If this definition of "consumer" applies to you, we must adhere to certain rights and obligations regarding your personal data.
</span>
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text"><u>Your rights with respect to your personal data</u>
</span>
</span></div><div ><span >
</span></div><ul><li ><span ><span data-custom-className="body_text">Right to be informed whether or not we are processing your personal data
</span>
</span></li></ul><div ><span >
</span></div><ul><li ><span ><span data-custom-className="body_text">Right to access your personal data
</span>
</span></li></ul><div ><span >
</span></div><ul><li ><span ><span data-custom-className="body_text">Right to correct inaccuracies in your personal data
</span>
</span></li></ul><div ><span >
</span></div><ul><li ><span ><span data-custom-className="body_text">Right to request deletion of your personal data
</span>
</span></li></ul><div ><span >
</span></div><ul><li ><span ><span data-custom-className="body_text">Right to obtain a copy of the personal data you previously shared with us
</span>
</span></li></ul><div ><span >
</span></div><ul><li ><span ><span data-custom-className="body_text">Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")
</span>
</span></li></ul><div ><span >
</span></div><div ><span ><u><span data-custom-className="body_text">Exercise your rights provided under the Virginia VCDPA
</span></u>
</span></div><div ><span >
<br />
</span></div><div ><span ><span data-custom-className="body_text">You may contact us by email at support@getaimodels.com or visit <span >mailto:support@getaimodels.com
</span>.
</span>
</span></div><div ><span >
<br />
</span></div><div ><span ><span data-custom-className="body_text">If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.
</span>
</span></div><div >
<br /></div><div ><span ><u><span data-custom-className="body_text">Verification process
</span></u>
<br />
</span></div><div ><span >
<br />
</span></div><div ><span ><span data-custom-className="body_text">We may request that you provide additional information reasonably necessary to verify you and your consumer's request. If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request.
</span>
</span></div><div ><span >
<br />
</span></div><div ><span ><span data-custom-className="body_text">Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.
</span>
</span></div><div >
<br /></div><div ><span ><span data-custom-className="body_text"><u>Right to appeal</u>
</span>
</span></div><div ><span >
<br />
</span></div><div ><span ><span data-custom-className="body_text">If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision, please email us at support@getaimodels.com. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may contact the <a data-custom-className="link" href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint" rel="noopener noreferrer" target="_blank"><span ><span data-custom-className="link">Attorney General to submit a complaint
</span>
</span></a>.
</span>
</span><span ><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><span data-custom-className="body_text"><span ><span ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span>
</span><span >
</span></div><div ><span >
<br />
</span></div><div ><span id="otherlaws" ><strong><span data-custom-className="heading_1">14. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
</span></strong>
</span></div><div ><span >
<br />
</span></div><div ><span ><em><strong><span data-custom-className="body_text">In Short:
</span></strong><span data-custom-className="body_text">&nbsp;You may have additional rights based on the country you reside in.
</span></em>
</span></div><div ><span >
<br />
</span></div><div ><span ><span data-custom-className="heading_2">
</span><span data-custom-className="heading_2"><strong>Australia</strong> <strong>and</strong> <strong>New Zealand</strong>
</span><span data-custom-className="heading_2">
</span>
</span></div><div ><span >
<br />
</span></div><div ><span ><span data-custom-className="body_text">We collect and process your personal information under the obligations and conditions set by Australia's Privacy Act 1988 and New Zealand's Privacy Act 2020 (Privacy Act).
</span>
</span></div><div ><span >
<br />
</span></div><div ><span ><span data-custom-className="body_text">This privacy notice satisfies the notice requirements defined in both Privacy Acts, in particular: what personal information we collect from you, from which sources, for which purposes, and other recipients of your personal information.
</span>
</span></div><div ><span >
<br />
</span></div><div ><span ><span data-custom-className="body_text">If you do not wish to provide the personal information necessary to fulfill their applicable purpose, it may affect our ability to provide our services, in particular:
</span>
</span></div><div ><span >
</span></div><ul><li ><span ><span data-custom-className="body_text">offer you the products or services that you want
</span>
</span></li></ul><div ><span >
</span></div><ul><li ><span ><span data-custom-className="body_text">respond to or help with your requests
</span>
</span></li></ul><div ><span >
</span><div ><span >
</span><div ><span ><span data-custom-className="body_text">At any time, you have the right to request access to or correction of your personal information. You can make such a request by contacting us by using the contact details provided in the section "<a data-custom-className="link" href="#request"><span ><span data-custom-className="link">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
</span>
</span></a>"
</span><span data-custom-className="body_text">
</span>
</span></div><div ><span >
<br />
</span></div><div ><span >I<span data-custom-className="body_text">f you believe we are unlawfully processing your personal information, you have the right to submit a complaint about a breach of the Australian Privacy Principles to the <a data-custom-className="link" href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us" rel="noopener noreferrer" target="_blank"><span ><span data-custom-className="link">Office of the Australian Information Commissioner
</span>
</span></a> and a breach of New Zealand's Privacy Principles to the <a data-custom-className="link" href="https://www.privacy.org.nz/your-rights/making-a-complaint/" rel="noopener noreferrer" target="_blank"><span ><span data-custom-className="link">Office of New Zealand Privacy Commissioner
</span>
</span></a>.
</span>
</span><span >
</span></div><div ><span >
<br />
</span></div><div ><span ><strong><span data-custom-className="heading_2">Republic of South Africa
</span></strong>
</span></div><div ><span >
<br />
</span></div><div ><span ><span data-custom-className="body_text">At any time, you have the right to request access to or correction of your personal information. You can make such a request by contacting us by using the contact details provided in the section "
</span>
</span><span data-custom-className="link"><a href="#request"><span ><span data-custom-className="link">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
</span>
</span></a>
</span><span ><span data-custom-className="body_text">"
</span><span data-custom-className="body_text">
</span>
</span></div><div ><span >
<br />
</span></div><div ><span ><span data-custom-className="body_text">If you are unsatisfied with the manner in which we address any complaint with regard to our processing of personal information, you can contact the office of the regulator, the details of which are:
</span>
</span></div><div ><span >
<br />
</span></div><div ><a data-custom-className="link" href="https://inforegulator.org.za/" rel="noopener noreferrer" target="_blank"><span ><span data-custom-className="body_text"><span data-custom-className="link">The Information Regulator (South Africa)
</span>
</span>
</span></a></div><div ><span data-custom-className="body_text"><span >General enquiries:&nbsp;
</span><a data-custom-className="link" href="mailto:enquiries@inforegulator.org.za" rel="noopener noreferrer" target="_blank"><span ><span data-custom-className="link">enquiries@inforegulator.org.za
</span>
</span></a>
</span></div><div ><span data-custom-className="body_text"><span >Complaints (complete POPIA/PAIA form 5):&nbsp;
</span><a data-custom-className="link" href="mailto:PAIAComplaints@inforegulator.org.za" rel="noopener noreferrer" target="_blank"><span ><span data-custom-className="link">PAIAComplaints@inforegulator.org.za
</span>
</span></a><span >&nbsp;&amp;&nbsp;
</span>
</span><a data-custom-className="link" href="mailto:POPIAComplaints@inforegulator.org.za" rel="noopener noreferrer" target="_blank"><span ><span data-custom-className="body_text"><span data-custom-className="link">POPIAComplaints@inforegulator.org.za
</span>
</span>
</span></a><span >
</span><span >
</span></div><div >
<br /></div><div id="policyupdates" ><span ><span ><span ><span ><span id="control" ><strong><span data-custom-className="heading_1">15. DO WE MAKE UPDATES TO THIS NOTICE?
</span></strong>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><em><strong>In Short:&nbsp;</strong>Yes, we will update this notice as necessary to stay compliant with relevant laws.</em>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
</span>
</span>
</span></div><div >
<br /></div><div id="contact" ><span ><span ><span ><span ><span id="control" ><strong><span data-custom-className="heading_1">16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
</span></strong>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">If you have questions or comments about this notice, you may <span ><span data-custom-className="body_text">email us at support@getaimodels.com or&nbsp;
</span>
</span><span ><span ><span data-custom-className="body_text">contact us by post at:
</span>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text"><span ><span ><span ><span data-custom-className="body_text">Solon Labs GmbH
</span>
</span>
</span>
</span>
</span><span data-custom-className="body_text"><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span></div><div ><span ><span data-custom-className="body_text">Muehlbergstr. 28<span ><span >
</span>
</span>
</span>
</span></div><div ><span ><span data-custom-className="body_text">Vienna<span ><span >, Vienna 1140
</span>
</span>
</span>
</span></div><div ><span ><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><span >
</span>
</span>
</span>Austria<span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text"><span >
</span>
</span>
</span>
</span>
</span>
</span><span data-custom-className="body_text"><span ><span >
</span>
</span>
</span>
</span>
</span>
</span>
</span><span data-custom-className="body_text"><span ><span data-custom-className="body_text"><span ><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span><span >
</span><span ><span data-custom-className="body_text"><span ><span ><span data-custom-className="body_text">
</span>
</span>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div id="request" ><span ><span ><span ><span ><span id="control" ><strong><span data-custom-className="heading_1">17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
</span></strong>
</span>
</span>
</span>
</span>
</span></div><div >
<br /></div><div ><span ><span ><span data-custom-className="body_text">Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please 
</span><span data-custom-className="body_text">email: support@getaimodels.com
</span>
</span><span data-custom-className="body_text">.
</span>
</span></div>
      
      </div>
      </div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div>
                       </div>
                    </div>
                </div>
            </main>
        );
    };

    return (
        <>
            <NavbarTop />
            <PrivacyPolicy />
            <FooterBottom />
        </>
    );
};