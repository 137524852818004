// FooterBottom.js
import React from 'react';
import AppConfig from '../config/AppConfig';
import { ReactComponent as FooterLogo } from '../assets/NavbarLogo.svg'
import { Link } from "react-router-dom";

const FooterBottom = () => {
    return (
        <footer>
            <div className="container small border-top">
                <div className="row py-5 d-flex justify-content-between">
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4 p-5">
                        <FooterLogo width="24" height="24" />
                        <address className="text-secondary mt-3">
                            <strong>{AppConfig.name}</strong><br />
                            by <Link to={AppConfig.authorWebsite} className="link-fancy link-dark">{AppConfig.author}</Link><br />
                        </address>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4 p-5">
                        <h3 className="h6 mb-3">Company</h3>
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <Link to="/about" className="nav-link link-secondary ps-0">About</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="mailto:business@getaimodels.com" aria-label="Contact" className="nav-link link-secondary ps-0">
                                    Contact
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/privacy" className="nav-link link-secondary ps-0" >Privacy Policy</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4 p-5">
                        <h3 className="h6 mb-3">
                            Products
                        </h3>
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <Link to="/news" className="nav-link link-secondary ps-0" aria-label="Brings you to the latest AI news">Latest AI News</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/models" className="nav-link link-secondary ps-0" aria-label="Brings you to the best GenAI Model Leaderboard">GenAI Model Leaderboard</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/repos" className="nav-link link-secondary ps-0" aria-label="Brings you to the hottest AI Repositories">Trending AI Repos</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/prompts" className="nav-link link-secondary ps-0" aria-label="Brings you to the most creative GenAI Prompt Ideas">GenAI Prompt Ideas</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/guide" className="nav-link link-secondary ps-0" aria-label="Brings you to the AI Guide">AI Guide</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/faq" className="nav-link link-secondary ps-0" aria-label="Brings you to the AI FAQ">AI FAQ</Link>
                            </li>
                        </ul>
                    </div>
                
                </div>
            </div>
        </footer>
    );
};

export default FooterBottom;
