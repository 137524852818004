// Newspage.js
import React, { useEffect, useState, useContext } from 'react';
import NavbarTop from './NavbarTop';
import FooterBottom from './FooterBottom';
import NewsFeed from './NewsFeed'
import { useParams } from 'react-router-dom';

export default function NewsPage() {
    const { id } = useParams();

    function News() {

        return(
            <main>
                <div className="py-vh-4 w-100 overflow-hidden" id="news">
                    <div className="container">
                        <div className="row">
                            <div >
                                <h1 className="display-huge fw-bold pb-3">Latest AI News</h1>
                                <NewsFeed displayNewsId={id} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    };

    return (
        <>
            <NavbarTop />
            <News />
            <FooterBottom />
        </>
    );
};