// src/NewsTicker.js
import React, { useState, useContext, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import NewsDetail from './NewsDetail';
import formatDate from '../utils/formatDate';
import replaceHtml from '../utils/replaceHtml';
import parseHtml from '../utils/parseHtml';
import NewsContext from '../contexts/NewsContext';

const NewsFeed = (params) => {

    const { loadingNews, news } = useContext(NewsContext);
    const [showModal, setShowModal] = useState(false);
    const [selectedNewsIndex, setSelectedNewsIndex] = useState(null);

    useEffect(() => {
        if (params.displayNewsId && news) {
            let routedNewsIndex = parseInt(params.displayNewsId);
            handleNewsClick(routedNewsIndex); // simulate a click on the news that was in the routed params
            // console.log('set routed index:', routedNewsIndex)
            // console.log('params:', params)
        }
    }, [params, news]);
    
    const handleNewsClick = (index) => {
        // console.log(index)
        setSelectedNewsIndex(index);
        setShowModal(true);
    };
    
    const handlePrevNews = () => {
        const prevIndex = selectedNewsIndex - 1;
        setSelectedNewsIndex(prevIndex < 0 ? news.length - 1 : prevIndex);  // after the newest news item we go back to the last one
    };
    
    const handleNextNews = () => {
        const nextIndex = selectedNewsIndex + 1;
        setSelectedNewsIndex(nextIndex >= news.length ? 0 : nextIndex);
    };
    
    const handleCloseModal = () => {
        setSelectedNewsIndex(null);
        setShowModal(false);
    };

    function NewsBoxFeatured(news) {
        if (news.news.length < 1) return null;  // news doesn't exist until loaded by server!
        let newsItem = news.news[0];
        // make sure every item has a summary
        // console.log('test 1', newsItem)
        if (!("summary" in newsItem)) newsItem.summary = "";
        return (
            <section className="border-bottom mb-5">
                <div className="text-dark news-card" onClick={() => handleNewsClick(0)}>
                    <div className="row gx-5">
                        <div className="col-md-12 col-lg-8 col-xl-6 mb-4">
                            <div className="bg-image hover-overlay ripple shadow-2-strong rounded-5" data-mdb-ripple-color="light">
                                <img src={newsItem.image} alt={newsItem.title} className="img-fluid img-ratio-16-9 w-100" />
                            </div>
                        </div>

                        <div className="col-md-12 mb-4">
                            <span className="badge bg-danger px-2 py-1 shadow-1-strong mb-3">News of the day</span>
                            <h4><strong>{newsItem.title}</strong></h4>
                            <div className="row mb-3">
                                <i>{formatDate(newsItem.published)}</i>
                            </div>
                            <p className="text-muted news-summary">
                                {parseHtml(newsItem.summary.length > 1000 ? `${newsItem.summary.slice(0, 1000)}...` : newsItem.summary)} 
                            </p>
                            {/* <button type="button" className="btn btn-primary">Read more</button> */}
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    function NewsColumns(news) {
        // create a column of news items, with the first featured on top, then every third item of the rest (because we do 3 columns)
        if (news.news.length < 1) return null;  // news doesn't exist until loaded by server!
            
        let newsItems = news.news.slice(1); // leave the first item, as its the featured

        // drop first item
        // newsItems.shift()
        // split the news items into columns
        let newsItemsColumns = [];
        for (let i = 0; i < 3; i++) {
            // we create three columns
            let currentColumn = [];
            for (let j = i; j < newsItems.length; j += 3) {

                // make sure every item has a summary
                if (!("summary" in newsItems[j])) newsItems[j].summary = "";
                // keep the original index so we can navigate in the modal later!
                newsItems[j].id = j+1; // j+1 because we sliced the top featured news above
                //console.log(newsItems[j].id)
                // we add every third item to the current column
                currentColumn.push(newsItems[j]);
            }
            newsItemsColumns.push(currentColumn);
        }

        let outputCols = []

        newsItemsColumns.map((newsItemCol, index) =>  {
            // console.log(index.toString())
            let columnDivTop = (
                <>
                    

                    {/* <div className="row mb-3">
                        <div className="col-12">
                            <Link to="/" className="text-info">
                                <i className="fas fa-plane"></i>
                                Travels
                            </Link>
                        </div>
                        <div className="col-12 text-end">
                            <i>{formatDate(newsItemCol[0].published)}</i>
                        </div>
                    </div> */}

                    {/* <!-- Article title and description --> */}
                    <div className="text-dark news-card border-bottom mb-4" onClick={() => handleNewsClick(newsItemCol[0].id)} key={'col top ' + index.toString()}>
                        <div className="bg-image hover-overlay shadow-1-strong ripple rounded-5 mb-4"
                            data-mdb-ripple-color="light">
                            <img src={newsItemCol[0].image} className="img-fluid img-ratio-16-9" alt="test" />
                        </div>
                        <h5>{newsItemCol[0].title}</h5>
                        <p className="pb-2 news-summary">
                            {parseHtml(newsItemCol[0].summary.length > 1000 ? `${newsItemCol[0].summary.slice(0, 250)}...` : newsItemCol[0].summary)} 
                            {/* replace html tags and replace &nbsp; */}
                        </p>
                    </div>
                </>
            )
                
            let columnDivBottom = []
                for (let i = 1; i < 4; i++) { // if we add columndivtop we need to change to the following: let i = 1; i < 4; i++
                    let columnDivBottomPart = (
                        <div className="text-dark news-card" onClick={() => handleNewsClick(newsItemCol[i].id)} key={'col bot ' + i + ' ' + index.toString()}>
                            <div className="row">
                                {/* <div className="col-3">
                                    <img src="https://mdbcdn.b-cdn.net/img/new/standard/city/041.webp"
                                    className="img-fluid shadow-1-strong rounded" alt="Hollywood Sign on The Hill" />
                                </div> */}

                                <div className="col-12 ">
                                    <p className="mb-2">
                                        <strong>{newsItemCol[i].title}</strong>
                                    </p>
                                    <p className="border-bottom  pb-4  mb-4">
                                        <i>{formatDate(newsItemCol[i].published)}</i>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                    columnDivBottom.push(columnDivBottomPart);
                }

                // combine each column
                let combinedCol = (
                    <div className="col-lg-4 col-md-12 mb-4 mb-lg-0" key={'col com' + index.toString()}>
                        {columnDivTop}
                        {columnDivBottom}
                    </div>
                )

            // add each combined col to output
            outputCols.push(combinedCol);
            return null;
        })

        return outputCols;
    }

    
    return (
        <>
            {loadingNews ? (
                <div className="py-vh-3 pb-0 h-100 d-flex align-items-center justify-content-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <div className="news">
                    <NewsBoxFeatured news={news} />
                    <section>
                        <div className="row gx-lg-5">
                            <NewsColumns news={news} /> 
                        </div>
                    </section>

                    {/* Detailed view modal */}
                    {selectedNewsIndex !== null && (
                        <NewsDetail
                        show={showModal}
                        onHide={handleCloseModal}
                        newsItem={news[selectedNewsIndex]}
                        onPrev={handlePrevNews}
                        onNext={handleNextNews}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default NewsFeed;
