// AboutPage.js
import React from 'react';
import NavbarTop from './NavbarTop';
import FooterBottom from './FooterBottom';
import { Link } from "react-router-dom";

export default function AboutPage() {

    function About() {

        return(
            <main>
                <div className="py-vh-4 w-100 overflow-hidden" id="impressum">
                    <div className="container">
                        <div className="row d-flex align-items-center position-relative justify-content-center px-0 g-5">
                            <h1 className="display-huge fw-bold">About Us</h1>

                            <p>Welcome to <strong>getaimodels.com</strong>, your premier destination for cutting-edge insights into artificial intelligence (AI).
                            <br /><br />
                            We're dedicated to demystifying the world of AI for everyone. Whether you're a seasoned AI enthusiast or just beginning your journey, our platform offers a wealth of resources tailored to your needs.
                            <br /><br />
                            From <Link to="/news" className="link-fancy link-dark" aria-label="Brings you to the latest AI news">breaking news</Link> on the latest AI advancements to thought-provoking <Link to="/prompts" className="link-fancy link-dark" aria-label="Brings you to the best AI prompts">AI prompts</Link> designed to spark creativity, we've got you covered. Our comprehensive <Link to="/guide" className="link-fancy link-dark" aria-label="Brings you to the AI Guide">AI guide</Link> serves as your roadmap through the complexities of this rapidly evolving field, while our <Link to="/faq" className="link-fancy link-dark" aria-label="Brings you to the AI FAQ">AI FAQ</Link> answers your most pressing questions in clear, accessible language.
                            <br /><br />
                            But that's not all – we understand that choosing the right AI model can be daunting. That's why we provide a detailed comparison of different <Link to="/models" className="link-fancy link-dark" aria-label="Brings you to the best open source AI models">AI models</Link>, empowering you to make informed decisions.
                            <br /><br />
                            At <strong>getaimodels.com</strong>, we believe that knowledge is power, and AI should be accessible to all. Join us on this exciting journey as we explore the limitless possibilities of artificial intelligence together.</p>
                           
                        </div>
                    </div>
                </div>
            </main>
        );
    };

    return (
        <>
            <NavbarTop />
            <About />
            <FooterBottom />
        </>
    );
};