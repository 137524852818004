// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from 'firebase/database';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC1H1h5Bn7-_pVuogEdTRuR1RBXMBlY-uo",
  authDomain: "aiweb-a179b.firebaseapp.com",
  projectId: "aiweb-a179b",
  storageBucket: "aiweb-a179b.appspot.com",
  messagingSenderId: "99616471425",
  appId: "1:99616471425:web:52620b0b0a113facd41080",
  measurementId: "G-LQPYH1KZRT",
  databaseURL: "https://aiweb-a179b-default-rtdb.firebaseio.com"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getDatabase(app);
