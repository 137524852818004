// ReposProvider.js
import React, { useState, useEffect } from 'react';
import ReposContext from '../contexts/ReposContext';
import { db } from '../config/Firebase';
import { ref, get } from 'firebase/database';

const ReposProvider = ({ children }) => {
    const [loadingRepos, setLoadingRepos] = useState(true);
    const [reposList, setReposList] = useState([]);

    useEffect(() => {
        // NOTE: this is the new firebase v9 web modular api style of a db query
        const reposRef = ref(db, 'webcontent/repos');
        get(reposRef)
        .then((snapshot) => {
            if (snapshot.exists()) {
            const data = snapshot.val();
            if (data) {
                const values = Object.values(data);

                let flattened = flattenData(values)
                let sorted = sortData(flattened);
                setReposList(sorted);
                // console.log('[ReposProvider] Loaded repos.');
            }
            } else {
                // console.log("[ReposProvider] No data available");
            }
        })
        .catch((error) => {
            // console.error(error);
        })
        .finally(() => {
            // Set loading to false after fetching data
            setLoadingRepos(false); 
        });
    }, []);

    function flattenData(data) {
        let flattenedArray = [];
        // 1. go through different programming langs
        for (let i = 0; i < data.length; i++) {
            // 2. go through each repo
            let lang = Object.entries(data[i]);   // data[i] is not an array but a dict (Object), we need to get the entries to be able to iterate again below!
            for (let j = 0; j < lang.length; j++) {
                let repo = lang[j][1];
                flattenedArray.push(repo);
            }
        }
        return flattenedArray;
    }

    function sortData(data) {
        // sort by stars
        return data.sort((a, b) => {
            return b.stars_today - a.stars_today;
        })
    }

    // replace %23 with # for all repo langs
    function replacePercent23InLanguage(repos) {
        return repos.map(repo => {
            if (repo.language) {
                repo.language = repo.language.replace(/%23/g, '#');
            }
            return repo;
        });
    }

    const updatedReposList = replacePercent23InLanguage(reposList);

    return (
        <ReposContext.Provider
        value={{ loadingRepos, reposList: updatedReposList }}
        >
            {children}
        </ReposContext.Provider>
    );
};

export default ReposProvider;
