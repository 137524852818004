// ModelsProvider.js
import React, { useState, useEffect } from 'react';
import ModelsContext from '../contexts/ModelsContext';
import { db } from '../config/Firebase';
import { ref, get } from 'firebase/database';

const ModelsProvider = ({ children }) => {
    const [loadingModels, setLoadingModels] = useState(true);
    const [modelsData, setModelsData] = useState([]);
    const [organizationsData, setOrganizationsData] = useState([]);

    useEffect(() => {
        // NOTE: this is the new firebase v9 web modular api style of a db query
        const modelsRef = ref(db, 'webcontent/models/leaderboard');
        get(modelsRef).then((snapshot) => {
            if (snapshot.exists()) {
                const modelsSnapshot = snapshot.val();
                if (modelsSnapshot) {
                    const modelsValues = Object.values(modelsSnapshot);
                    setModelsData(modelsValues);
                    // console.log('[ModelsProvider] Loaded models.')
                    // console.log(modelsValues)

                }
            } else {
                // console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            // Set loading to false after fetching data
            setLoadingModels(false);
        });
        const orgasRef = ref(db, 'webcontent/static/organizations');
        get(orgasRef).then((snapshot) => {
            if (snapshot.exists()) {
                const orgasSnapshot = snapshot.val();
                if (orgasSnapshot) {
                    const orgasValues = Object.values(orgasSnapshot);
                    setOrganizationsData(orgasValues);
                }
            }
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoadingModels(false);
        });
    }, []);
    
    return (
        <ModelsContext.Provider
        value={{ loadingModels, modelsData, organizationsData }}
        >
            {children}
        </ModelsContext.Provider>
    );
};

export default ModelsProvider;
