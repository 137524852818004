// PromptsDetail.js

import React, {useState} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IoCheckmarkCircle , IoCopy } from "react-icons/io5";

export default function PromptsDetail({ show, onHide, promptItem, onPrev, onNext }) {

    // console.log("detail:", promptItem)
    const [copiedIndex, setCopiedIndex] = useState(null);


    const copyToClipboard = async (promptText) => {
        try {
            await navigator.clipboard.writeText(promptText);
            setCopiedIndex(promptText);
            setTimeout(() => {
                setCopiedIndex(null);
            }, 2000); // Hide the checkmark after 2 seconds
        } catch (error) {
            console.error('Error copying to clipboard:', error);
        }
    };


    return (
        <Modal show={show} onHide={onHide} dialogClassName="prompts-modal">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="row modal-prompts-container">
                    {/* <div className="col modal-nav-button modal-nav-button-start" onClick={onPrev}>
                        <p>{'<'}</p>
                    </div> */}

                    <div className="modal-prompts mb-4">
                        <h1><strong>{promptItem.title}</strong></h1>
                        <div className='w-100 d-flex mt-3 mb-3'>
                            {
                                (promptItem.keywords ? 
                                    promptItem.keywords.map((tag) => <div className="float-start prompt-keyword">{tag}</div>)
                                : null)
                            }
                        </div>
                        <p className="modal-text modal-prompts-text">
                            {promptItem.prompt}
                        </p>

                        <button
                        className="btn btn-primary rounded-3 mb-3"
                        onClick={() => copyToClipboard(promptItem.prompt)}
                        >
                            {copiedIndex === promptItem.prompt 
                            ? <><div className="button-icon"><IoCheckmarkCircle /></div><span className='button-text'>Copied</span></> 
                            : <><div className="button-icon"><IoCopy /></div><span className='button-text'>Copy</span></> }
                        </button>
                    </div>
                    {/* <div className="modal-nav-button modal-nav-button-end" onClick={onNext}>
                        <p>{'>'}</p>
                    </div> */}
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button variant="primary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer> */}
        </Modal>
    );
};
