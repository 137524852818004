import React from 'react';

function parseHtml(htmlString) {
    const parsedString = removePersonalUseOnly(htmlString);
    return <div dangerouslySetInnerHTML={{ __html: parsedString }}></div>;
}

function removePersonalUseOnly(htmlString) {
    // Replace %23 with #
    htmlString = replacePercent23(htmlString);
    // Remove "For personal use only." string
    return htmlString.replace(/For personal use only\./g, '');
}

function replacePercent23(htmlString) {
    return htmlString.replace(/%23/g, '#');
}

export default parseHtml;
export { replacePercent23 };
