// AppConfig.js
// Base Config

const AppConfig = {
    name: "getaimodels", // the main website name
    title: "getaimodels: AI News, Prompts and Models", // the main app (website) title
    description: "Artificial Intelligence (AI) News, the best Prompts and Models in one place!",
    author: "Solon Labs",
    authorWebsite: "https://solon-labs.com",
    themeColor: "#FFFFFF",
    opengraphUrl: "https://getaimodels.com/img/getaimodels.jpg",
    // rssUrls: ["https://techcrunch.com/feed/"], // the list of RSS feeds to be loaded
    // corsProxy: "https://corsproxy.io/?",  // Note: some RSS feeds can't be loaded in the browser due to CORS security. To get around this we use a proxy. See also: https://www.npmjs.com/package/rss-parser
    version: "1.1"
};
export default AppConfig;