// App.js
import React, { useEffect } from 'react'
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Home from './components/Home';
import ModelsPage from './components/ModelsPage';
import ReposPage from './components/ReposPage';
import PromptsPage from './components/PromptsPage';
import GuidePage from './components/GuidePage';
import NewsPage from './components/NewsPage';
import FaqPage from './components/FaqPage';
import AboutPage from './components/AboutPage';
import PrivacyPage from './components/PrivacyPage';
import CookiesPage from './components/CookiesPage';
import ImpressumPage from './components/ImpressumPage';
import ErrorPage from "./components/ErrorPage";
import { Helmet } from "react-helmet";
import AppConfig from './config/AppConfig';
import useScript from './hooks/useScript';
import AOS from "aos";
import "aos/dist/aos.css";
import NewsProvider from './providers/NewsProvider';
import PromptsProvider from './providers/PromptsProvider';
import ModelsProvider from './providers/ModelsProvider';
import ReposProvider from './providers/ReposProvider';

// table style
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

// Router sets the path for each element
const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <ErrorPage />,
    },
    {
        path: "news",
        element: <NewsPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "news/:id",
        element: <NewsPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "prompts",
        element: <PromptsPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "prompts/:id",
        element: <PromptsPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "models",
        element: <ModelsPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "repos",
        element: <ReposPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "repos/:id",
        element: <ReposPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "guide",
        element: <GuidePage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "faq",
        element: <FaqPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "about",
        element: <AboutPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "privacy",
        element: <PrivacyPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "cookies",
        element: <CookiesPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "impressum",
        element: <ImpressumPage />,
        errorElement: <ErrorPage />,
    },
]);


function App() {

    useEffect(() => {
        AOS.init(
            {duration: 800}
        );
        AOS.refresh();
    }, []);
    
    // scripts we want to add to the page bottom
    useScript('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js');    // for adsense

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{AppConfig.title}</title>
                <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no" />
                <meta name="description" content={AppConfig.description} />
                <meta name="author" content={AppConfig.author} />
                <meta name="theme-color" content={AppConfig.themeColor} />
                <meta name="HandheldFriendly" content="true" />
                {/* <meta property="og:title" content={AppConfig.title} />
                <meta property="og:description" content={AppConfig.description} />
                <meta property="og:image" content={AppConfig.opengraphUrl} /> */}
                {/* // react bootstrap cdn */}
                {/* <link
                    rel="stylesheet"
                    href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
                    integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM"
                    crossorigin="anonymous"
                /> */}
                {/* <link rel="stylesheet" type="text/css" href="/css/theme.css" /> */}
                {/* {{<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />}} */}
            </Helmet>
            <NewsProvider>
                <PromptsProvider>
                    <ModelsProvider>
                        <ReposProvider>
                            <RouterProvider router={router} />
                        </ReposProvider>
                    </ModelsProvider>
                </PromptsProvider>
            </NewsProvider>
        </>
      );
}

export default App;