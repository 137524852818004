import React, { useEffect  } from 'react';
import AdConfig from '../config/AdConfig.js';

const AdsenseAd = (props) => {
    const { dataAdSlot } = props;  // needs to be passed on construction, this is the adblock id
    const dataAdClient = AdConfig.adClient;

    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
        }
    },[]);

    return (
        <>
            <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client={dataAdClient}
                data-ad-slot={dataAdSlot}
                data-ad-format="auto"
                data-full-width-responsive="true">
            </ins>
        </>
    );
};

export default AdsenseAd;
